import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo_2.png";
import {
  ActivityIcon,
  BookOpenIcon,
  Fingerprint,
  HardDriveIcon,
  HeadphonesIcon,
  Home,
  LogOutIcon,
  Settings,
  User2,
  UserIcon,
  Users,
} from "lucide-react";
import { useGroupStore } from "stores/useGroup";
import { useUser } from "stores/useUser";
import * as Popover from '@radix-ui/react-popover';
import { useTranslation } from "react-i18next";

export function Sidebar() {
  const activeHref = useLocation();
  const group = useGroupStore(store => store.group);
  const user = useUser(state => state.user);
  const can = useUser(state => state.can);
  const { t } = useTranslation()

  return (
    <aside className="fixed h-screen w-56 flex-shrink-0 bg-white p-5 gap-3 flex flex-col border-r-zinc-200 border-r">
      <img src={logo} alt="logo" className="h-14 p-2 object-contain mb-2" />
      <Link
        to="/"
        className="py-2 items-center gap-2 px-3 text-sm font-medium text-zinc-600 rounded-md hover:bg-zinc-100 flex transition-colors"
      >
        <Home size={16} />
        {t("sidebar.home")}
      </Link>
      {can(["admin"]) && <Link
        to="/grupos"
        data-active={activeHref.pathname === "/grupos"}
        className="py-2 items-center gap-2  px-3 text-sm font-medium text-zinc-600 rounded-md hover:bg-zinc-100 flex transition-colors data-[active=true]:bg-primary data-[active=true]:text-white"
      >
        <Users size={16} />
        {t("sidebar.groups")}
      </Link>}
      {group && (
        <>
          <span className="w-full h-px bg-zinc-200" />
          <div className="text-sm font-semibold flex gap-2 items-center px-3 text-primary">
            <Users size={16} />
            {group.name}
          </div>
          {can(["operator", "coordinator", "manager"]) && <Link
            to={`/grupos/${group.id}/drive`}
            data-active={activeHref.pathname.includes(`/grupos/${group.id}/drive`)}
            className="py-2 items-center gap-2  px-3 text-sm font-medium text-zinc-600 rounded-md hover:bg-zinc-100 flex transition-colors data-[active=true]:bg-primary data-[active=true]:text-white"
          >
            <HardDriveIcon size={16} />
            {t("sidebar.drive")}
          </Link>}
          {can(["admin", "coordinator", "manager"]) && <Link
            to={`/grupos/${group.id}/usuarios`}
            data-active={activeHref.pathname.includes(`/grupos/${group.id}/usuarios`)}
            className="py-2 items-center gap-2  px-3 text-sm font-medium text-zinc-600 rounded-md hover:bg-zinc-100 flex transition-colors data-[active=true]:bg-primary data-[active=true]:text-white"
          >
            <UserIcon size={16} />
            {t("sidebar.users")}
          </Link>}
          {can(["admin", "operator", "coordinator", "manager"]) && <Link
            to={`/grupos/${group.id}/pacientes`}
            data-active={activeHref.pathname.includes(`/grupos/${group.id}/pacientes`)}
            className="py-2 items-center gap-2  px-3 text-sm font-medium text-zinc-600 rounded-md hover:bg-zinc-100 flex transition-colors data-[active=true]:bg-primary data-[active=true]:text-white"
          >
            <ActivityIcon size={16} />
            {t("sidebar.patients")}
          </Link>}
          {can(["admin"]) && <Link
            to={`/grupos/${group.id}/roteiros`}
            data-active={activeHref.pathname === `/grupos/${group.id}/roteiros`}
            className="py-2 items-center gap-2  px-3 text-sm font-medium text-zinc-600 rounded-md hover:bg-zinc-100 flex transition-colors data-[active=true]:bg-primary data-[active=true]:text-white"
          >
            <BookOpenIcon size={16} />
            {t("sidebar.guides")}
          </Link>}
          {can(["admin", "coordinator"]) && <Link
            to={`/grupos/${group.id}/especialistas`}
            data-active={activeHref.pathname === `/grupos/${group.id}/especialistas`}
            className="py-2 items-center gap-2  px-3 text-sm font-medium text-zinc-600 rounded-md hover:bg-zinc-100 flex transition-colors data-[active=true]:bg-primary data-[active=true]:text-white"
          >
            <HeadphonesIcon size={16} />
            {t("sidebar.experts")}
          </Link>}
          {can(["admin", "coordinator"]) && <Link
            to={`/grupos/${group.id}/configuracoes`}
            data-active={activeHref.pathname === `/grupos/${group.id}/configuracoes`}
            className="py-2 items-center gap-2  px-3 text-sm font-medium text-zinc-600 rounded-md hover:bg-zinc-100 flex transition-colors data-[active=true]:bg-primary data-[active=true]:text-white"
          >
            <Settings size={16} />
            {t("sidebar.settings")}
          </Link>}
        </>
      )}

      <span className="flex-1" />
      {user &&
      <Popover.Root>
        <Popover.Trigger>
          <button className="py-2 w-full items-center gap-2 px-3 text-sm font-medium text-zinc-600 rounded-md bg-zinc-100 hover:bg-zinc-100 flex transition-colors">
            <span className="relative h-12 w-12 rounded-full bg-zinc-300 flex justify-center items-center text-zinc-400 overflow-hidden">
              <User2 />
              {user.avatar && <img
                src={user.avatar}
                alt=""
                className="m-0 h-full w-full absolute"
              />}
            </span>
            <div className="flex flex-col text-left">
              {user.name}
              <span className="text-xs text-black/30">{user.roles?.map(r => t("userRole." + r.name)).join(", ")}</span>
            </div>
          </button>
        </Popover.Trigger>
        <Popover.Content
        className="rounded p-2 w-24 bg-zinc-500/10 backdrop-blur-md shadow-md"
        sideOffset={5}
      >
        <div className="flex flex-col gap-2.5">
          <Link to="/logout" className="flex gap-2 items-center justify-center px-2 py-1 hover:bg-zinc-300 rounded">
            <LogOutIcon size={16} />
            {t("sidebar.exit")}
          </Link>
        </div>
        <Popover.Arrow className="fill-zinc-500/10" />
      </Popover.Content>
      </Popover.Root>}
      <span className="w-full text-xs text-center text-zinc-400">v1.2.2</span>
    </aside>
  );
}
