import { Input } from "./input";
import { InputPassword } from "./inputPassword";
import { Label } from "./label";
import { Select } from "./select";

export const Form = {
    Input,
    Select,
    InputPassword,
    Label
}