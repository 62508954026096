/* eslint-disable no-restricted-globals */
import { Button } from "components/Button";
import { Users } from "lucide-react";
import { Group, GroupCategory, GroupStatus } from "models/group";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import { GroupDialog } from "./GroupDialog";
import dayjs from "dayjs";
import { useGroupStore } from "stores/useGroup";
import { useUser } from "stores/useUser";
import { useTranslation } from "react-i18next";

export default function Painel() {
    const user = useUser(state => state.user);
    const can = useUser(state => state.can);
    const setGroup = useGroupStore(state => state.setGroup);
    const [groups, setGroups] = useState<Group[] | null>(null);
    const [groupDialog, setGroupDialog] = useState(false);
    const [editGroup, setEditGroup] = useState<Group | null>(null);
    const navigate = useNavigate();
    const { t } = useTranslation()

    function selectGroup(group: Group) {
        navigate(`/grupos/${group.id}`);
    }
    function refresh() { 
        setGroup(null);
        api.get("/groups")
            .then(res => setGroups(res.data));
    }
    function handleNewGroup() {
        setEditGroup(null);
        setGroupDialog(true);
    }
    function handleSubmit() {
        setEditGroup(null);
        setGroupDialog(false);
        refresh();
    }
    function handleUser()
    {
        if (user)
        {
            if (can(["admin"]) === false)
            {
                navigate(`/grupos/${user?.groupId}`);
                return;
            }
        }
    }
    useEffect(handleUser, [user]);
    useEffect(refresh, []);

    if (user === null)
        return <></>;

    if (groups === null) {
      return (
        <main className="prose w-full mx-auto max-w-5xl">
          <div className="flex justify-between">
            <div>
              <div className="h-6 w-96 rounded bg-zinc-300 animate-pulse" />
              <div className="mt-2 h-6 w-32 rounded bg-zinc-300 animate-pulse" />
            </div>
          </div>
        </main>
      );
    }

    return (
        <main className="prose w-full mx-auto max-w-5xl">
            <div className="flex justify-between">
                <div>
                    <h2 className="m-0">{t("groups.title")}</h2>
                    <p className="m-0">{t("groups.description")}</p>
                </div>
                <div className="flex items-center">
                    <Button onClick={handleNewGroup}>
                        {t("groups.new")}
                    </Button>
                </div>
            </div>
            <div className="bg-white rounded-md border border-zinc-200 mt-10">
                <table className="m-0">
                    <tbody>
                        {groups.map(group => (
                            <tr onClick={_ => selectGroup(group)} key={group.id} className="hover:bg-zinc-100 cursor-pointer group">
                                <td className="p-3 align-middle">
                                    <span id="name" className="flex gap-2 items-center">
                                        <Users />
                                        <div className="flex flex-col">
                                            <span className="font-bold">{group.name}</span>
                                            <span className="text-xs text-zinc-400">{t("groupCategory." + GroupCategory[group.category])}</span>
                                        </div>
                                    </span>
                                </td>
                                <td className="p-3 align-middle">
                                    <div className="flex flex-col">
                                        <span className="text-xs text-zinc-400">{t("groups.listUser")}</span>
                                        {group.users.length} {t("groups.listUser").toLowerCase()}
                                    </div>
                                </td>
                                <td className="p-3 align-middle">
                                    <div className="flex flex-col">
                                        <span className="text-xs text-zinc-400">{t("groups.listState")}</span>
                                        {t("groupStatus." + GroupStatus[group.status])}
                                    </div>
                                </td>
                                <td className="p-3 align-middle">
                                    <div className="flex flex-col">
                                        <span className="text-xs text-zinc-400">{t("groups.listInit")}</span>
                                        {dayjs(group.createdAt).format(t("time.date"))}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <GroupDialog group={editGroup} open={groupDialog === true} setOpen={setGroupDialog} onSubmit={handleSubmit} />
        </main>
    )
}