import { Button } from "components/Button";
import { Form } from "components/Form";
import api from "services/api";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useUser } from "stores/useUser";
import { jwtDecode } from "jwt-decode";
import { UserToken } from "models/userToken";
import logo from "../../assets/logo_2.png";
import { useTranslation } from "react-i18next";

const loginFormSchema = z.object({
    email: z.string()
        .nonempty("login.insertEmail"),
    password: z.string()
        .nonempty("login.insertPassword"),
})

type LoginFormData = z.infer<typeof loginFormSchema>

export default function LoginPage() {
    const { t } = useTranslation()
    
    const { 
        register, 
        handleSubmit,
        setError, 
        formState: { errors, isDirty } 
    } = useForm<LoginFormData>({
        resolver: zodResolver(loginFormSchema)
    });
    const router = useNavigate();
    const [sending, setSending] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    async function handleLogin({password, email}: LoginFormData) {
        setSending(true);
        try {
            removeCookie("token");
            const res = await api.post("/auth/login", {
                password,
                email
            })
            const user = jwtDecode<UserToken>(res.data);
            setCookie('token', res.data, { path: '/' });
            api.defaults.headers['Authorization'] = `Bearer ${res.data}`;

            if (user.provisional === "True") {
                router("/resetPassword")
            } else {
                router("/grupos")
            }

        } catch {
            setError("password", {message: t("login.invalidPassword")});
        } finally {
            setSending(false);
        }
    }

    return (
        <body>
            <main className="relative prose w-screen h-screen flex justify-center items-center max-w-none bg-zinc-300 bg-login bg-cover">
                <form onSubmit={handleSubmit(handleLogin)} className="flex flex-col gap-4 drop-shadow bg-white p-6 rounded-md items-center">
                    <img src={logo} alt="logo" className="h-8 m-0" />
                    <div className="flex flex-col gap-1 w-full">
                        <Form.Label htmlFor="email">{t("login.email")}</Form.Label>
                        <Form.Input register={register} label="email"/>
                        {errors.email && <span>{t(errors.email?.message!)}</span>}
                    </div>
                    <div className="flex flex-col gap-1">
                        <Form.Label htmlFor="password">{t("login.password")}</Form.Label>
                        <Form.InputPassword placeholder={t("login.insertPassword")} register={register}/>
                        {errors.password && <span>{t(errors.password?.message!)}</span>}
                    </div>
                    <Button type="submit" disabled={!isDirty} className="w-full" loadingText={sending && t("login.entering")}>
                        {t("login.enter")}
                    </Button>
                </form>
            </main>
        </body>
    )
}