import { create } from 'zustand'
import { UserToken } from 'models/userToken'

interface UserState {
    user: UserToken | null
    can: (roles: string[]) => boolean,
    setUser: (user: UserToken|null) => void,
}

export const useUser = create<UserState>()(
    (set, get) => ({
        user: null,
        can: (roles:string[]) => {
            const user = get().user;
            if (!user)
                return false;
            return user.roles?.some(r => roles.includes(r.name));
        },
        setUser: (user:UserToken|null) => set((state) => ({user})),
    }),
)