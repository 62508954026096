import React, { useEffect, useRef, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { RemoteExpert } from 'models/remoteExpert';
import { Button } from 'components/Button';
import { z } from 'zod';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from 'react-hook-form';
import api from 'services/api';
import { Group } from 'models/group';
import { useTranslation } from 'react-i18next';

const expertFormSchema = z.object({
  avatar: z.any(),
  name: z.string()
      .nonempty("insertName")
      .min(3, "minCharacters"),
  objectId: z.string()
      .nonempty("insertObject")
      .min(3, "minCharacters"),
  role: z.string()
      .optional().nullable(),
})

type RemoteExpertFormData = z.infer<typeof expertFormSchema>

interface RemoteExpertDialogProps {
    open: boolean,
    setOpen: (open:boolean) => void,
    onSubmit: () => void,
    expert: RemoteExpert | null,
    group: Group,
}
export function ExpertDialog({open, setOpen, onSubmit, expert, group}: RemoteExpertDialogProps) {
  const { 
      register, 
      handleSubmit,
      reset,
      watch,
      formState: { errors, isDirty } 
  } = useForm<RemoteExpertFormData>({
      resolver: zodResolver(expertFormSchema)
  });
  
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  
  const [openedAvatar, setOpenedAvatar] = useState<File | undefined>();
  const [sending, setSending] = useState(false);
  const { t } = useTranslation()

  async function submit(data: RemoteExpertFormData) {
    setSending(true);

    let currentExpert = expert;

    if (currentExpert) {
      await api.put(`/experts/${currentExpert.id}`, {id: currentExpert.id, ...data});
    } else {
      const res = await api.post(`/groups/${group.id}/experts`, {...data});
      currentExpert = res.data;
    }

    if (openedAvatar) {
      const form = new FormData();
      form.append('file', openedAvatar);
      
      await api.put(`/experts/${currentExpert?.id}/photo`, form);
    }

    setSending(false);
    onSubmit();
  }

  useEffect(() => {
    setOpenedAvatar(undefined);
    if (expert) {
      reset({
        name: expert.name,
        avatar: expert.avatar,
        objectId: expert.objectId,
        role: expert.role,
      })
    } else {
      reset({
        name: "",
        objectId: "",
        avatar: null,
        role: null,
      });
    }
  }, [expert, open])

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Portal>
            <Dialog.Overlay className="bg-black/50 data-[state=open]:animate-overlayShow fixed inset-0" />
            <Dialog.Content className="overflow-y-auto data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
              <Dialog.Title className="text-mauve12 m-0 text-[17px] font-medium">
                {expert ? t("expertsDialog.editExpert") : t("expertsDialog.newExpert")}
              </Dialog.Title>
              <Dialog.Description className="text-mauve11 mt-[10px] mb-5 text-[15px] leading-normal">
              {t("expertsDialog.description")}
              </Dialog.Description>
              <form onSubmit={handleSubmit(submit)}>
              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="name">
                  {t("expertsDialog.name")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  {...register("name")}
                />
              </fieldset>
              {errors?.name && <span className='text-red-600 w-full flex justify-end mb-4'>{t("expertsDialog." + errors?.name?.message)}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="objectId">
                  Microsoft Object ID
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  type="objectId"
                  {...register("objectId")}
                />
              </fieldset>
              {errors?.objectId && <span className='text-red-600 w-full flex justify-end mb-4'>{t("expertsDialog." + errors?.objectId?.message)}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="role">
                {t("expertsDialog.role")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  type="role"
                  {...register("role")}
                />
              </fieldset>
              {errors?.role && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.role?.message}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="crm">
                {t("expertsDialog.photo")}
                </label>
                <input 
                    type="file"
                    multiple={false} 
                    className="hidden"
                    ref={inputFileRef}
                    accept="image/*"
                    onChange={(e) => e.currentTarget?.files ? setOpenedAvatar(e.currentTarget.files[0]) : setOpenedAvatar(undefined)}
                />
                <img src={openedAvatar ? URL.createObjectURL(openedAvatar) : (expert?.avatar ?? undefined)} alt="" className="h-12 w-12 rounded-full bg-zinc-300" />
                <button onClick={() => inputFileRef.current?.click()} type="button">{t("expertsDialog.change")}</button>
              </fieldset>
              <div className="mt-[25px] flex justify-end">
                <Button type='submit' disabled={(!isDirty && openedAvatar == null) || sending}>
                 {sending ? t("expertsDialog.saving") : t("expertsDialog.save")}
                </Button>
              </div>
              </form>
              <Dialog.Close asChild>
                <button
                  className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                  aria-label="Close"
                >
                  <X />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      );
}
