import { InputHTMLAttributes, useState } from "react";
import { Input } from "./input"
import { Eye, EyeOff } from "lucide-react";
import clsx from "clsx";
import { UseFormRegister } from "react-hook-form";

interface InputProps extends InputHTMLAttributes<HTMLInputElement>
{
    className?: string;
    label?: string;
    placeholder: string;
    register: UseFormRegister<any>;
}
export const InputPassword = ({ className, placeholder, register, label="password", ...props } : InputProps) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Input 
            className={
                clsx(
                    'pr-16',
                    className
                )
            }
            placeholder={placeholder}
            type={showPassword ? "text" : "password"}
            { ...props }
            label={label}
            register={register}
        >
            <div className='absolute top-0 right-5 bottom-0 items-center flex'>
                <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="text-slate-400 hover:text-slate-700 transition"
                >
                    {showPassword ? <EyeOff/> : <Eye/>}
                </button>
            </div>
        </Input>
    )
}