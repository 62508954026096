import { ButtonHTMLAttributes, ReactNode } from "react"
import { clsx } from 'clsx';
import { Loader2 } from "lucide-react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children?: ReactNode,
    className?: string,
    loadingText?: string | boolean,

}
export const Button = ({ children, className, loadingText, ...props }: ButtonProps) => {
    return (
        <button 
            disabled={loadingText !== undefined && loadingText !== false}
            type="button"
            className={clsx(
                className,
                "px-4 text-sm py-2 rounded-md bg-primary text-white flex gap-1 items-center justify-center active:bg-zinc-700 transition",
                "hover:opacity-90",
                "disabled:bg-zinc-400 disabled:cursor-not-allowed disabled:scale-100",
                "active:scale-95",
            )}
            {...props}
        >
            {loadingText 
            ? <>
                <Loader2 className="animate-spin"/>
                {loadingText}...
            </>
            :<>{children}</>}
        </button>
    )
}