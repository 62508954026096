import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { clsx } from "clsx";
import { useGroupStore } from "stores/useGroup";

export function LayoutGroupPage() {
  const { groupId } = useParams();
  const group = useGroupStore(state => state.group);
  const load = useGroupStore(state => state.load);

  useEffect(() => {
    if (!groupId)
      return;
    if (group == null || group?.id !== parseInt(groupId))
      load(parseInt(groupId));
  }, []);

  if (group === null) {
    return (
      <main className="prose w-full mx-auto max-w-5xl">
        <div className="flex justify-between">
          <div>
            <div className="h-6 w-96 rounded bg-zinc-300 animate-pulse" />
            <div className="mt-2 h-6 w-32 rounded bg-zinc-300 animate-pulse" />
          </div>
        </div>
      </main>
    );
  }
  return (
    <main className="prose w-full mx-auto max-w-5xl">
      <Outlet />
    </main>
  );
}