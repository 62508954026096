/* eslint-disable no-restricted-globals */
import { ActivityIcon, BookOpenIcon, HardDriveIcon, HeadphonesIcon, UserIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGroupStore } from "stores/useGroup";
import { useUser } from "stores/useUser";

export function HomePage() {
  const { t } = useTranslation()
  const group = useGroupStore((state) => state.group);
  const can = useUser(state => state.can);

  if (group === null) return <></>;
  return (
    <main className="flex flex-col">
      <h2 className="m-0 mb-10">{group.name}</h2>
      <div className="grid gap-4 lg:grid-cols-4 grid-cols-2">
        {can(["operator", "coordinator", "manager"]) && <Link to={`/grupos/${group.id}/drive`} className="bg-primary h-56 transition hover:bg-secondary rounded-md p-4 text-white flex flex-col justify-around items-center no-underline">
          <HardDriveIcon size={64} />
          <span className="text-lg font-bold">{t("home.drive")}</span>
        </Link>}
        {can(["admin", "coordinator", "manager"]) && <Link to={`/grupos/${group.id}/usuarios`} className="bg-primary h-56 transition hover:bg-secondary rounded-md p-4 text-white flex flex-col justify-around items-center no-underline">
          <UserIcon size={64} />
          <span className="text-lg font-bold">{t("home.users")}</span>
        </Link>}
        {can(["admin", "operator", "coordinator", "manager"]) &&<Link to={`/grupos/${group.id}/pacientes`} className="bg-primary h-56 transition hover:bg-secondary rounded-md p-4 text-white flex flex-col justify-around items-center no-underline">
          <ActivityIcon size={64} />
          <span className="text-lg font-bold">{t("home.patients")}</span>
        </Link>}
        {can(["admin"]) && <Link to={`/grupos/${group.id}/roteiros`} className="bg-primary h-56 transition hover:bg-secondary rounded-md p-4 text-white flex flex-col justify-around items-center no-underline">
          <BookOpenIcon size={64} />
          <span className="text-lg font-bold">{t("home.guides")}</span>
        </Link>}
        {can(["admin", "coordinator"]) && <Link to={`/grupos/${group.id}/especialistas`} className="bg-primary h-56 transition hover:bg-secondary rounded-md p-4 text-white flex flex-col justify-around items-center no-underline">
          <HeadphonesIcon size={64} />
          <span className="text-lg font-bold">{t("home.experts")}</span>
        </Link>}
      </div>
    </main>
  );
}
