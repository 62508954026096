
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { redirect, useNavigate } from "react-router-dom";
import { useUser } from "stores/useUser";


export default function LogoutPage() {
    const setUser = useUser(state => state.setUser);
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const navigate = useNavigate();

    useEffect(() => {
        removeCookie("token");
        setUser(null);
        navigate("/login");
    }, [])

    return (
        <></>
    )
}