import { useEffect } from "react";
import { ContextMenuItem, useContextMenu } from "../stores/useContextMenu";

export function ContextMenu() {
  const menu = useContextMenu((state) => state.menu);
  const close = useContextMenu((state) => state.close);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (e.pageX !== menu?.point.x)
        close()
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [menu]);


  if (menu === null)
    return <></>;

  return <ul className="bg-white drop-shadow-md absolute rounded-md overflow-hidden p-2 shadow-lg z-50" style={{left: menu.point.x, top: menu.point.y}}>
    {menu.items.map(item => {
      const Ic = item.icon;
      return (
        <button type="button" disabled={item.disabled} className="disabled:opacity-20 disabled:hover:bg-transparent w-full disabled:cursor-not-allowed text-zinc-500 flex items-center px-3 py-2 text-sm hover:bg-zinc-100 group rounded cursor-default" onClick={item.onClick}>
          {Ic && <Ic size={16} className="mr-2 group-hover:text-primary"/>}
          {item.title}
        </button>
      )
    })}
  </ul>
}