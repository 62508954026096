import axios from "axios";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

export default axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        Authorization: `Bearer ${cookies.get("token")}`
    }
})