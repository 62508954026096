/* eslint-disable no-restricted-globals */
import { Button } from "components/Button";
import {
  PenLine,
  Trash,
  X,
} from "lucide-react";
import { useGroupStore } from "stores/useGroup";
import { z } from "zod";
import {  GroupCategory, GroupCategoryValues, GroupStatus, GroupStatusValues } from "models/group";
import { useEffect, useRef, useState } from "react";
import api from "services/api";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useUser } from "stores/useUser";
import { MainLayoutGroupPage } from "./mainLayout";
import { useTranslation } from "react-i18next";

const groupFormSchema = z.object({
  avatar: z.any(),
  name: z
    .string()
    .nonempty("insertName")
    .min(3, "minCharacters"),
  status: z.nativeEnum(GroupStatus),
  category: z.nativeEnum(GroupCategory),
  address: z.string().optional().nullable(),
  notes: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  teamsLink: z.string().optional().nullable(),
  teamsEmail: z.string().optional().nullable(),
  phone: z.string().optional().nullable(),
});

type GroupFormData = z.infer<typeof groupFormSchema>;

export function GroupSettingPage() {
  const group = useGroupStore((state) => state.group);
  const user = useUser(state => state.user);
  const can = useUser((state) => state.can);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<GroupFormData>({
    resolver: zodResolver(groupFormSchema),
  });
  
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  
  const [openedAvatar, setOpenedAvatar] = useState<File | undefined>();
  const [sending, setSending] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation()
  function handleUser()
  {
      if (user)
      {
          if (can(["admin", "coordinator"]) === false)
          {
              navigate(`/grupos/${user?.groupId}`);
              return;
          }
      }
  }
  useEffect(handleUser, [user]);

  function refresh()
  {
    navigate(0)
  }

  function removeGroup() {
    if (confirm(t("settings.deleteAlert").replace("%name%", group?.name!))) {
        api.delete(`/groups/${group?.id}`)
            .then(refresh);
    }
  }

  async function submit(data: GroupFormData) {
    setSending(true);
    try {
      if (group) {
        await api.put(`/groups/${group.id}`, { id: group.id, ...data });
      } else {
        await api.post(`/groups`, data);
      }
      if (openedAvatar) {
        const form = new FormData();
        form.append('file', openedAvatar);
        
        await api.put(`/groups/${group?.id}/photo`, form);
      }
      refresh();
    } catch {
      window.alert(t("settings.sendError"));
    }
    setSending(false);
  }

  useEffect(() => {
    setOpenedAvatar(undefined);
    if (group) {
      reset({
        name: group.name,
        status: group.status,
        category: group.category,
        address: group.address,
        notes: group.notes,
        phone: group.phone,
        email: group.email,
        teamsLink: group.teamsLink,
        teamsEmail: group.teamsEmail,
        avatar: group.avatar,
      });
    } else {
      reset({
        name: "",
        status: 1,
        category: 1,
        address: "",
        notes: "",
        phone: "",
        email: "",
        teamsLink: "",
        teamsEmail: "",
        avatar: null,
      });
    }
  }, [group]);

  if (group === null) return <></>;
  return (
    <MainLayoutGroupPage>
      <div className="flex justify-between items-center p-4 border-b border-zinc-300">
        <h4 className="m-0">{t("settings.description")}</h4>
      </div>
      <form className="p-8" onSubmit={handleSubmit(submit)}>
        <fieldset className="mb-[15px] flex items-center gap-5">
          <label className="w-[90px] text-right text-[15px]" htmlFor="name">
          {t("settings.name")}
          </label>
          <input
            className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
            {...register("name")}
          />
        </fieldset>
        {errors?.name && (
          <span className="text-red-600 w-full flex justify-end mb-4">
            {t("settings." + errors?.name?.message)}
          </span>
        )}

        <fieldset className="mb-[15px] flex items-center gap-5">
          <label className="w-[90px] text-right text-[15px]" htmlFor="status">
          {t("settings.state")}
          </label>
          <select
            className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
            {...register("status", { valueAsNumber: true })}
          >
            {GroupStatusValues.map((status, index) => (
              <option key={index} value={index + 1}>
                {t("groupStatus." + status)}
              </option>
            ))}
          </select>
        </fieldset>
        {errors?.status && (
          <span className="text-red-600 w-full flex justify-end mb-4">
            {errors?.status?.message}
          </span>
        )}

        <fieldset className="mb-[15px] flex items-center gap-5">
          <label className="w-[90px] text-right text-[15px]" htmlFor="category">
          {t("settings.category")}
          </label>
          <select
            className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
            {...register("category", { valueAsNumber: true })}
          >
            {GroupCategoryValues.map((category, index) => (
              <option key={index} value={index + 1}>
                {t("groupCategory." + category)}
              </option>
            ))}
          </select>
        </fieldset>
        {errors?.category && (
          <span className="text-red-600 w-full flex justify-end mb-4">
            {errors?.category?.message}
          </span>
        )}

        <fieldset className="mb-[15px] flex items-center gap-5">
          <label className="w-[90px] text-right text-[15px]" htmlFor="address">
          {t("settings.address")}
          </label>
          <input
            className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
            type="text"
            {...register("address")}
          />
        </fieldset>
        {errors?.address && (
          <span className="text-red-600 w-full flex justify-end mb-4">
            {errors?.address?.message}
          </span>
        )}

        <fieldset className="mb-[15px] flex items-center gap-5">
          <label className="w-[90px] text-right text-[15px]" htmlFor="email">
          {t("settings.email")}
          </label>
          <input
            className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
            type="email"
            {...register("email")}
          />
        </fieldset>
        {errors?.email && (
          <span className="text-red-600 w-full flex justify-end mb-4">
            {errors?.email?.message}
          </span>
        )}

        <fieldset className="mb-[15px] flex items-center gap-5">
          <label className="w-[90px] text-right text-[15px]" htmlFor="teamsLink">
          {t("settings.link")}
          </label>
          <input
            className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
            type="text"
            {...register("teamsLink")}
          />
        </fieldset>
        <fieldset className="mb-[15px] flex items-center gap-5">
          <label className="w-[90px] text-right text-[15px]" htmlFor="teamsEmail">
          {t("settings.operatorEmail")}
          </label>
          <input
            className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
            type="email"
            {...register("teamsEmail")}
          />
        </fieldset>
        {errors?.teamsLink && (
          <span className="text-red-600 w-full flex justify-end mb-4">
            {errors?.teamsLink?.message}
          </span>
        )}

        <fieldset className="mb-[15px] flex items-center gap-5">
          <label className="w-[90px] text-right text-[15px]" htmlFor="phone">
          {t("settings.phone")}
          </label>
          <input
            className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
            type="phone"
            {...register("phone")}
          />
        </fieldset>
        {errors?.phone && (
          <span className="text-red-600 w-full flex justify-end mb-4">
            {errors?.phone?.message}
          </span>
        )}

        <fieldset className="mb-[15px] flex items-center gap-5">
          <label className="w-[90px] text-right text-[15px]" htmlFor="notes">
          {t("settings.notes")}
          </label>
          <textarea
            className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
            {...register("notes")}
          />
        </fieldset>
        {errors?.notes && (
          <span className="text-red-600 w-full flex justify-end mb-4">
            {errors?.notes?.message}
          </span>
        )}


        <fieldset className="mb-[15px] flex items-center gap-5">
          <label className="w-[90px] text-right text-[15px]" htmlFor="crm">
          {t("settings.photo")}
          </label>
          <input 
              ref={inputFileRef}
              type="file"
              multiple={false} 
              className="hidden"
              accept="image/*"
              onChange={(e) => e.currentTarget?.files ? setOpenedAvatar(e.currentTarget.files[0]) : setOpenedAvatar(undefined)}
          />
          <img src={openedAvatar ? URL.createObjectURL(openedAvatar) : (group?.avatar ?? undefined)} alt="" className="h-24 w-44 bg-zinc-300" />
          <button onClick={() => inputFileRef.current?.click()} type="button" className="flex justify-center items-center gap-2 hover:underline">
          {t("settings.change")}
            <PenLine size={16}/>
          </button>
        </fieldset>
        <div className="mt-[25px] flex justify-end">
          <Button type="submit" disabled={(!isDirty && openedAvatar == null) || sending}>
            {sending ? t("settings.saving") : t("settings.save")}
          </Button>
        </div>
      </form>
      <span className="w-full h-px bg-zinc-300" />
      <div className="p-4">
          <button onClick={removeGroup} className="flex gap-2 items-center rounded px-2 py-1 bg-red-600 text-white">
            <Trash size={16}/>
            {t("settings.delete")}
          </button>
      </div>
    </MainLayoutGroupPage>
  );
}
