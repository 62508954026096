/* eslint-disable no-restricted-globals */
import { Button } from "components/Button";
import { HeadphonesIcon, Pen, PlusIcon, Trash } from "lucide-react";
import { RemoteExpert } from "models/remoteExpert";
import { useEffect, useState } from "react";
import { ExpertDialog } from "./ExpertDialog";
import api from "services/api";
import { useGroupStore } from "stores/useGroup";
import { useUser } from "stores/useUser";
import { useNavigate } from "react-router-dom";
import { MainLayoutGroupPage } from "./mainLayout";
import { useTranslation } from "react-i18next";

export function GroupExpertPage() {
  const group = useGroupStore((state) => state.group);
  const user = useUser((state) => state.user);
  const can = useUser((state) => state.can);
  const reload = useGroupStore((state) => state.reload);
  const [expertDialog, setExpertDialog] = useState(false);
  const [editExpert, setEditExpert] = useState<RemoteExpert | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation()

  function handleUser() {
    if (user) {
      if (can(["admin", "coordinator"]) === false) {
        navigate(`/grupos/${user?.groupId}`);
        return;
      }
    }
  }
  useEffect(handleUser, [user]);
  function handleSubmitUser() {
    setExpertDialog(false);
    setEditExpert(null);
    reload();
  }

  function handleEditExpert(expert: RemoteExpert) {
    setEditExpert(expert);
    setExpertDialog(true);
  }

  function handleNewExpert() {
    setEditExpert(null);
    setExpertDialog(true);
  }

  function handleRemoveExpert(expert: RemoteExpert) {
    if (
      confirm(
        t("experts.deleteAlert").replace("%name%", expert.name)
      )
    ) {
      api.delete(`/experts/${expert.id}`).then(reload);
    }
  }

  if (group === null) return <></>;

  return (
    <MainLayoutGroupPage
      subtitle={t("experts.description")}
      actionButton={
        <Button onClick={handleNewExpert}>
          <PlusIcon size={16} />
          {t("experts.new")}
        </Button>
      }
    >
      <table className="m-0">
        <tbody>
          {group.experts.map((expert) => (
            <tr key={expert.id} className="hover:bg-zinc-100 group">
              <td className="p-3 align-middle">
                <span className="flex gap-2 items-center">
                  {expert.avatar ? (
                    <img
                      src={expert.avatar}
                      alt=""
                      className="m-0 h-12 w-12 rounded-full bg-zinc-300"
                    />
                  ) : (
                    <span className="h-12 w-12 rounded-full bg-zinc-300 flex justify-center items-center text-zinc-400">
                      <HeadphonesIcon />
                    </span>
                  )}
                  <div className="flex flex-col">
                    <span className="font-bold">{expert.name}</span>
                  </div>
                </span>
              </td>
              <td className="p-3 align-middle">
                <div className="flex flex-col">
                  <span className="text-xs text-zinc-400">Object Id</span>
                  {expert.objectId}
                </div>
              </td>
              <td className="p-3 align-middle text-right">
                <div className="invisible group-hover:visible">
                  <button
                    onClick={(_) => handleRemoveExpert(expert)}
                    className="p-2 rounded text-zinc-800 hover:bg-red-200 hover:text-red-500"
                  >
                    <Trash size={20} />
                  </button>
                  <button
                    onClick={(_) => handleEditExpert(expert)}
                    className="p-2 rounded hover:bg-zinc-200 text-zinc-800"
                  >
                    <Pen size={20} />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ExpertDialog
        expert={editExpert}
        open={expertDialog === true}
        setOpen={setExpertDialog}
        group={group}
        onSubmit={handleSubmitUser}
      />
    </MainLayoutGroupPage>
  );
}
