import React, { useEffect, useRef, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { User } from 'models/user';
import { Button } from 'components/Button';
import { z } from 'zod';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from 'react-hook-form';
import api from 'services/api';
import { Group } from 'models/group';
import { useUser } from 'stores/useUser';
import { useTranslation } from 'react-i18next';

const userFormSchema = z.object({
  avatar: z.any(),
  name: z.string()
      .nonempty("insertName")
      .min(3, "minCharacters"),
  phone: z.string()
      .optional().nullable(),
  email: z.string()
      .nonempty("insertEmail"),
  crm: z.string()
      .optional().nullable(),
  role: z.string()
      .optional().nullable(),
  linkedin: z.string()
      .optional().nullable(),
  notes: z.string()
      .optional().nullable(),
  systemRole: z.string()
      .optional().nullable(),
})

type UserFormData = z.infer<typeof userFormSchema>

interface UserDialogProps {
    open: boolean,
    setOpen: (open:boolean) => void,
    onSubmit: () => void,
    user: User | null,
    group: Group | null,
    isAdmin?: boolean,
}
export function UserDialog({open, setOpen, onSubmit, user, group, isAdmin = false}: UserDialogProps) {
  const { 
      register, 
      handleSubmit,
      reset,
      setValue,
      watch,
      formState: { errors, isDirty } 
  } = useForm<UserFormData>({
      resolver: zodResolver(userFormSchema)
  });
  
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  
  const [openedAvatar, setOpenedAvatar] = useState<File | undefined>();
  const [sending, setSending] = useState(false);
  const can = useUser(state => state.can);
  const watchPermission = watch("systemRole");
  const { t } = useTranslation()

  async function submit(data: UserFormData) {
    setSending(true);

    let currentUser = user;

    if (currentUser) {
      await api.put(`/users/${currentUser.id}`, {id: currentUser.id, ...data});
    } else {
      const res = await api.post(group ? `/groups/${group.id}/users` : `/users`, {...data});
      handleCopyPassword(res.data.password?.password);
      currentUser = res.data;
    }

    if (openedAvatar) {
      const form = new FormData();
      form.append('file', openedAvatar);
      
      await api.put(`/users/${currentUser?.id}/photo`, form);
    }

    setSending(false);

    onSubmit();
  }

  function handleCopyPassword(pass: string) {
    if (pass) {
      navigator.clipboard.writeText(pass);
      alert(t("usersDialog.passwordTempAlert"));
    }
  }

  async function handleNewPassword() {
    const res = await api.post("/auth/newPassword", {
      email: user?.email
    });
    handleCopyPassword(res.data);
  }

  useEffect(() => {
    setOpenedAvatar(undefined);
    if (user) {
      reset({
        name: user.name,
        phone: user.phone,
        avatar: user.avatar,
        email: user.email,
        crm: user.crm,
        role: user.role,
        linkedin: user.linkedin,
        notes: user.notes,
        systemRole: user.roles.length > 0 ? user.roles[0].name : null,
      })
    } else {
      reset({
        name: "",
        phone: "",
        email: "",
        crm: "",
        role: "",
        linkedin: "",
        notes: "",
        systemRole: isAdmin ? "admin" : "operator",
        avatar: null,
      });
    }
  }, [user, open])

    return ( 
        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Portal>
            <Dialog.Overlay className="bg-black/50 data-[state=open]:animate-overlayShow fixed inset-0" />
            <Dialog.Content className="overflow-y-auto data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
              <Dialog.Title className="text-mauve12 m-0 text-[17px] font-medium">
                {user ? t("usersDialog.editUser") : t("usersDialog.newUser")}
              </Dialog.Title>
              <Dialog.Description className="text-mauve11 mt-[10px] mb-5 text-[15px] leading-normal">
                {t("usersDialog.description")}
              </Dialog.Description>
              <form onSubmit={handleSubmit(submit)}>
              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="name">
                  {t("usersDialog.name")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  {...register("name")}
                />
              </fieldset>
              {errors?.name && <span className='text-red-600 w-full flex justify-end mb-4'>{t("usersDialog." + errors?.name?.message)}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="phone">
                {t("usersDialog.phone")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  type="phone"
                  {...register("phone")}
                />
              </fieldset>
              {errors?.phone && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.phone?.message}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="email">
                {t("usersDialog.email")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  type="email"
                  {...register("email")}
                />
              </fieldset>
              {errors?.email && <span className='text-red-600 w-full flex justify-end mb-4'>{t("usersDialog." + errors?.email?.message)}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="crm">
                {t("usersDialog.crm")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  type="text"
                  {...register("crm")}
                />
              </fieldset>
              {errors?.crm && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.crm?.message}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="role">
                {t("usersDialog.role")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  type="text"
                  {...register("role")}
                />
              </fieldset>
              {errors?.role && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.role?.message}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="linkedin">
                {t("usersDialog.linkedin")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  type="text"
                  {...register("linkedin")}
                />
              </fieldset>
              {errors?.linkedin && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.linkedin?.message}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="notes">
                {t("usersDialog.notes")}
                </label>
                <textarea
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  {...register("notes")}
                />
              </fieldset>
              {errors?.notes && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.notes?.message}</span>}

              {can(["admin", "coordinator"]) && <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="systemRole">
                {t("usersDialog.permissions")}
                </label>
                <select 
                  value={watchPermission ?? "operator"}
                  onChange={e => setValue("systemRole", e.target.value, { shouldDirty: true })}
                >
                  {isAdmin ? <>
                    {can(["admin"]) && <option value="admin">{t("userRole.admin")}</option>}
                  </>
                  :
                  <>
                    {can(["admin"]) && <option value="coordinator">{t("userRole.coordinator")}</option>}
                    {can(["admin", "coordinator"]) && <option value="manager">{t("userRole.manager")}</option>}
                    <option value="operator">{t("userRole.operator")}</option>
                  </>}
                </select>
              </fieldset>}
              {errors?.systemRole && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.systemRole?.message}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="crm">
                  {t("usersDialog.photo")}
                </label>
                <input 
                    ref={inputFileRef}
                    type="file"
                    multiple={false} 
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => e.currentTarget?.files ? setOpenedAvatar(e.currentTarget.files[0]) : setOpenedAvatar(undefined)}
                />
                <img src={openedAvatar ? URL.createObjectURL(openedAvatar) : (user?.avatar ?? undefined)} alt="" className="h-12 w-12 rounded-full bg-zinc-300" />
                <button onClick={() => inputFileRef.current?.click()} type="button">{t("usersDialog.change")}</button>
              </fieldset>
              <div className="mt-[25px] flex justify-end items-center">
                {user && <>
                  <button type='button' className='hover:underline text-primary' onClick={handleNewPassword}>{t("usersDialog.newPassword")}</button>
                  <span className='flex-1' />
                </>}
                <Button type='submit' disabled={(!isDirty && openedAvatar == null) || sending}>
                 {sending ? t("usersDialog.saving") : t("usersDialog.save")}
                </Button>
              </div>
              </form>
              <Dialog.Close asChild>
                <button
                  className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                  aria-label="Close"
                >
                  <X />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      );
}
