/* eslint-disable no-restricted-globals */
import { Button } from "components/Button";
import { Loader2, Pen, Trash, UserIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import dayjs from "dayjs";
import { useUser } from "stores/useUser";
import { User } from "models/user";
import { UserDialog } from "./[groupId]/UserDialog";

export default function AdminSettings() {
  const user = useUser((state) => state.user);
  const can = useUser((state) => state.can);
  const [users, setUsers] = useState<User[] | null>(null);
  const [editUser, setEditUser] = useState<User | null>(null);
  const [userDialog, setUserDialog] = useState(false);
  const navigate = useNavigate();

  function refresh() {
    setUsers(null);
    api.get("/users/admins").then((res) => setUsers(res.data));
  }
  function handleNewUser() {
    setEditUser(null);
    setUserDialog(true);
  }
  function handleSubmit() {
    setEditUser(null);
    setUserDialog(false);
    refresh();
  }
  function handleUser() {
    if (user) {
      if (can(["admin"]) === false) {
        navigate(`/grupos/${user?.groupId}`);
        return;
      }
    }
  }
  function handleRemoveUser(user: User) {
    if (
      confirm(
        `Você tem certeza que deseja remover o usuário '${user.name}'?\n\nTodas as suas informações serão removidas, incluindo as evidências dos guias.`
      )
    ) {
      api.delete(`/users/${user.id}`).then(refresh);
    }
  }

  function handleEditUser(user: User) {
    setEditUser(user);
    setUserDialog(true);
  }
  function handleSubmitUser() {
    setUserDialog(false);
    setEditUser(null);
    refresh();
  }
  useEffect(handleUser, [user]);
  useEffect(refresh, []);

  if (user === null) return <></>;

  if (can(["admin"]) === false)
    return <>Você não tem autorização para acessar essa pagina</>

  if (users === null) {
    return (
      <main className="prose w-full mx-auto max-w-5xl">
        <div className="flex justify-between">
          <div>
            <div className="h-6 w-96 rounded bg-zinc-300 animate-pulse" />
            <div className="mt-2 h-6 w-32 rounded bg-zinc-300 animate-pulse" />
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className="prose w-full mx-auto max-w-5xl">
      <div className="flex justify-between">
        <div>
          <h2 className="m-0">Configurações</h2>
          <p className="m-0">Usuários administradores.</p>
        </div>
        <div className="flex items-center">
          <Button onClick={handleNewUser}>Novo Grupo</Button>
        </div>
      </div>
      <div className="bg-white rounded-md border border-zinc-200 mt-10">
        <table className="m-0">
          <tbody>
            {users.map((user) => (
              <tr key={user.id} className="hover:bg-zinc-100 group">
                <td className="p-3 align-middle">
                  <span className="flex gap-2 items-center">
                    {user.avatar ? (
                      <span className="relative h-12 w-12 rounded-full bg-zinc-300 flex justify-center items-center text-zinc-400 overflow-hidden">
                        <Loader2 className="animate-spin" />
                        <img
                          src={user.avatar}
                          alt=""
                          className="m-0 h-full w-full absolute"
                        />
                      </span>
                    ) : (
                      <span className="h-12 w-12 rounded-full bg-zinc-300 flex justify-center items-center text-zinc-400">
                        <UserIcon />
                      </span>
                    )}
                    <div className="flex flex-col">
                      <span className="font-bold">{user.name}</span>
                      <span className="text-xs text-zinc-400">
                        {user.roles.map((r) => r.description).join(", ")}
                      </span>
                    </div>
                  </span>
                </td>
                <td className="p-3 align-middle">
                  <div className="flex flex-col">
                    <span className="text-xs text-zinc-400">E-mail</span>
                    {user.email}
                  </div>
                </td>
                <td className="p-3 align-middle">
                  <div className="flex flex-col">
                    <span className="text-xs text-zinc-400">Cadastro</span>
                    {dayjs(user.createdAt).format("DD/MM/YYYY")}
                  </div>
                </td>
                <td className="p-3 align-middle">{user.crm}</td>
                <td className="p-3 align-middle text-right">
                  <div className="invisible group-hover:visible">
                    <button
                      onClick={(_) => handleRemoveUser(user)}
                      className="p-2 rounded text-zinc-800 hover:bg-red-200 hover:text-red-500"
                    >
                      <Trash size={20} />
                    </button>
                    <button
                      onClick={(_) => handleEditUser(user)}
                      className="p-2 rounded hover:bg-zinc-200 text-zinc-800"
                    >
                      <Pen size={20} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <UserDialog
          user={editUser}
          open={userDialog === true}
          setOpen={setUserDialog}
          group={null}
          onSubmit={handleSubmitUser}
          isAdmin
        />
      </div>
    </main>
  );
}
