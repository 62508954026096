/* eslint-disable no-restricted-globals */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import { Button } from "components/Button";
import { LineChart, Pen, PlusIcon, Trash } from "lucide-react";
import dayjs from "dayjs";
import { Patient } from "models/patient";
import { PatientDialog } from "./PatientDialog";
import * as Switch from "@radix-ui/react-switch";
import { useGroupStore } from "stores/useGroup";
import { useUser } from "stores/useUser";
import { MainLayoutGroupPage } from "./mainLayout";
import { useTranslation } from "react-i18next";

export function GroupPatientPage() {
  const can = useUser((state) => state.can);
  const group = useGroupStore((state) => state.group);
  const reload = useGroupStore((state) => state.reload);
  const [patientDialog, setPatientDialog] = useState(false);
  const [editPatient, setEditPatient] = useState<Patient | null>(null);
  const navigate = useNavigate();
  const [sending, setSending] = useState(false);
  const { t } = useTranslation()

  function handleSubmitPatient() {
    setPatientDialog(false);
    setEditPatient(null);
    reload();
  }

  function handleEditPatient(patient: Patient) {
    setEditPatient(patient);
    setPatientDialog(true);
  }

  function handleNewPatient() {
    setEditPatient(null);
    setPatientDialog(true);
  }

  async function handleSwitch(patient: Patient, value: boolean) {
    setSending(true);
    await api.put(`/patients/${patient.id}`, {
      ...patient,
      enabled: value,
    });
    reload();
    setSending(false);
  }

  function handleRemovePatient(patient: Patient) {
    if (
      confirm(
        t("patients.deleteAlert").replaceAll("%name%", patient.name)
      )
    ) {
      api.delete(`/patients/${patient.id}`).then(reload);
    }
  }

  if (group === null) return <></>;

  return (
    <MainLayoutGroupPage
      subtitle={t("patients.description")}
      actionButton={
        <Button onClick={handleNewPatient}>
          <PlusIcon size={16} />
          {t("patients.new")}
        </Button>
      }
    >
      <table className="m-0">
        <tbody>
          {group.patients.map((patient) => (
            <tr key={patient.id} className="hover:bg-zinc-100 group">
              <td className="p-3 align-middle w-12">
                <Switch.Root
                  className="cursor-pointer w-[42px] h-[25px] bg-zinc-400 disabled:opacity-20 rounded-full relative data-[state=checked]:bg-primary outline-none"
                  disabled={sending}
                  checked={patient.enabled}
                  onCheckedChange={(v) => handleSwitch(patient, v)}
                >
                  <Switch.Thumb className="block w-[20px] h-[20px] bg-white rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
                </Switch.Root>
              </td>
              <td className="p-3 align-middle">
                <span className="flex gap-2 items-center">
                  <div className="flex flex-col">
                    <span className="font-bold">{patient.name}</span>
                    <span className="text-xs text-zinc-400">
                      {patient.idNumber}
                    </span>
                  </div>
                </span>
              </td>
              <td className="p-3 align-middle">
                <div className="flex flex-col">
                  <span className="text-xs text-zinc-400">{t("patients.listInteractions")}</span>
                  {patient.interactions?.length === 0 && t("patients.listNoInteractions")}
                  {patient.interactions?.length === 1 && t("patients.listOneInteractions")}
                  {patient.interactions?.length > 1 && t("patients.listNInteractions").replace("%n%", patient.interactions?.length.toString())}
                </div>
              </td>
              <td className="p-3 align-middle">
                <div className="flex flex-col">
                  <span className="text-xs text-zinc-400">{t("patients.listRegistration")}</span>
                  {dayjs(patient.createdAt).format(t("time.date"))}
                </div>
              </td>
              <td className="p-3 align-middle text-right">
                <div className="invisible group-hover:visible">
                  {can(["admin", "manager", "coordinator"]) && patient.interactions?.length === 0 && (
                    <button
                      onClick={(_) => handleRemovePatient(patient)}
                      className="p-2 rounded text-zinc-800 hover:bg-red-200 hover:text-red-500"
                    >
                      <Trash size={20} />
                    </button>
                  )}
                  <button
                    onClick={(_) => handleEditPatient(patient)}
                    className="p-2 rounded hover:bg-zinc-200 text-zinc-800"
                  >
                    <Pen size={20} />
                  </button>
                  <button
                    onClick={(_) =>
                      navigate(`/grupos/${group?.id}/pacientes/${patient.id}`)
                    }
                    className="p-2 rounded hover:bg-zinc-200 text-zinc-800"
                  >
                    <LineChart size={20} />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PatientDialog
        patient={editPatient}
        open={patientDialog === true}
        setOpen={setPatientDialog}
        group={group}
        onSubmit={handleSubmitPatient}
      />
    </MainLayoutGroupPage>
  );
}
