/* eslint-disable no-restricted-globals */
import { useCallback, useEffect, useState } from "react";
import api from "services/api";
import { Button } from "components/Button";
import {
  FileIcon,
  Loader2,
  PenLine,
  PlusIcon,
  Trash2,
} from "lucide-react";
import { useUser } from "stores/useUser";
import { Asset } from "models/asset";
import { useDropzone } from 'react-dropzone';
import { useContextMenu } from "stores/useContextMenu";
import { useTranslation } from "react-i18next";

export function DrivePage() {
  const openMenu = useContextMenu((state) => state.open);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [sending, setSending] = useState(false);
  const [selected, setSelected] = useState<Asset | undefined>(undefined);
  const loggedUser = useUser((state) => state.user);
  const { t } = useTranslation()
  
  const onDrop = useCallback(handleUpload, [])
  const {getRootProps, getInputProps, isDragActive, inputRef} = useDropzone({onDrop, noClick: true})

  async function handleRefresh() {
    if (!loggedUser)
      return;

    const res = await api.get(`users/${loggedUser?.id}`);
    if (res.data) {
      setAssets(res.data.assets);
    }
  }

  async function handleUpload(files:File[]) {
    setSending(true);
    try {
      var formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
      }
      const res = await api.post(`drive/user/${loggedUser?.id}`, formData);
      console.log(res.data)
      handleRefresh();
    } catch {
      alert(t("drive.sendError"));
    }
    setSending(false);
  }
  async function handleDelete(asset: Asset) {
    if (confirm(t("drive.deleteAlert").replace("%name%", asset.title))) {
      await api.delete(`drive/${asset.id}/user/${loggedUser?.id}`);
      handleRefresh();
    }
  }
  async function handleRename(asset: Asset) {
    const newTitle = prompt(t("drive.renameFile"), asset.title)
    if (newTitle) {
      await api.put(`drive/${asset.id}`, {
        Title: newTitle
      });
      handleRefresh();
    }
  }
  function handleContextMenu(e: React.MouseEvent<HTMLDivElement>, asset: Asset) {
    e.preventDefault();
    setSelected(asset);
    openMenu({x: e.pageX, y: e.pageY}, [
      {
        title: t("drive.delete"),
        onClick: () => handleDelete(asset),
        icon: Trash2
      },
      {
        title: t("drive.rename"),
        onClick: () => handleRename(asset),
        icon: PenLine
      }
    ])
  }

  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    const target:any = e.target;
    if (target?.id === "back") {
      setSelected(undefined);
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (!selected)
      return;
    if (e.code === "F2") {
      handleRename(selected);
    }
    if (e.code === "Delete") {
      handleDelete(selected);
    }
  }

  useEffect(() => {
    handleRefresh();
  }, [loggedUser])

  return (
    <main
        id="back" 
        data-drag={isDragActive} 
        className="prose w-full mx-auto max-w-5xl h-full focus:outline-none outline-2 outline-primary/50 outline-offset-8 data-[drag=true]:outline-dashed"  
        {...getRootProps()} 
        onKeyDown={handleKeyDown}
        onClick={handleClick}>
      <div className="flex justify-between mb-10">
        <div>
          <h2 className="m-0">{t("drive.title")}</h2>
          <p className="m-0">{t("drive.description")}</p>
        </div>
      </div>
      <input {...getInputProps()} />

      <div className="flex rounded-md border border-zinc-200 bg-white p-2">
        {selected && <>
          <button 
            className="p-2 px-3 rounded flex gap-2 items-center hover:bg-zinc-100 group text-zinc-500 text-sm"
            onClick={_ => handleDelete(selected)}>
              <Trash2 className="group-hover:text-red-500" size={16} />
              {t("drive.delete")}
          </button>
          <button 
            className="p-2 px-3 rounded flex gap-2 items-center hover:bg-zinc-100 group text-zinc-500 text-sm"
            onClick={_ => handleRename(selected)}>
            <PenLine className="group-hover:text-primary" size={16} />
            {t("drive.rename")}
          </button>
        </>}
        <span className="flex-1"/>
        <Button disabled={sending} onClick={_ => inputRef?.current?.click()}>
          {sending ? <>
            <Loader2 className="animate-spin" size={16} />
            {t("drive.sending")}
          </> : <>
            <PlusIcon size={16} />
            {t("drive.import")}
          </>}
        </Button>
      </div>

      <div 
        id="back" 
        className="grid grid-cols-1 lg:grid-cols-5 sm:grid-cols-3 gap-2 p-2 select-none" >
        {assets.map(asset => 
          <div 
            data-active={selected?.guid === asset.guid}
            className="overflow-hidden cursor-pointer flex flex-col items-center gap-2 rounded-md border border-transparent hover:border-zinc-300 p-2 data-[active=true]:border-primary data-[active=true]:outline outline-2 outline-primary/50" 
            onContextMenu={e => handleContextMenu(e, asset)}
            onClick={_ => setSelected(asset)}>
            <div className="bg-white drop-shadow-md text-zinc-300 w-32 h-32 rounded overflow-hidden relative flex items-center justify-center">
              <FileIcon />
              <img 
                className="w-full h-full absolute top-0 left-0 object-contain m-0"
                src={`${process.env.REACT_APP_STORAGE}/thumbnails/${asset.guid}.jpg`} 
                alt="" 
                onError={(event) => event.currentTarget.style.display = 'none'} />
            </div>
            <span className="overflow-ellipsis text-sm text-zinc-500 flex items-center flex-1 justify-center text-center">
              {asset.title}
            </span>
          </div>
        )}
      </div>
    </main>
  );
}
