import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import api from "services/api";
import { Sidebar } from "components/Sidebar";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { useUser } from "stores/useUser";
import { UserToken } from "models/userToken";
import { createPortal } from "react-dom";
import { ContextMenu } from "components/ContextMenu";

export default function PainelLayout() {
    const [logged, setLogged] = useState(true);
    const router = useNavigate();
    const setUser = useUser(state => state.setUser);
    const [cookies, setCookie] = useCookies(['token']);

    function checkAuth() {
      api.get("/groups")
        .then(() => {
          setLogged(true);
          const user:any = jwtDecode<UserToken>(cookies["token"]);
          setUser({
            ...user,
            roles: JSON.parse(user.role)
          });
        })
        .catch(() => router("/login"));
    }

    useEffect(checkAuth, []);

    if (!logged) <></>;
    return (
            <div className='min-h-screen bg-zinc-100'>
              <Sidebar/>
              <div className='pl-64 p-8 min-h-screen h-full flex w-full overflow-x-auto'>
                <Outlet/>
              </div>
              {createPortal(
                <ContextMenu />,
                document.body,
              )}
            </div>
    )
}