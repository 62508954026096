import React, { useEffect, useRef, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { Button } from 'components/Button';
import { z } from 'zod';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from 'react-hook-form';
import api from 'services/api';
import { Group, GroupStatus, GroupCategory, GroupStatusValues, GroupCategoryValues } from 'models/group';
import { useTranslation } from 'react-i18next';

const groupFormSchema = z.object({
  avatar: z.any(),
  name: z.string()
      .nonempty("insertName")
      .min(3, "minCharacters"),
  status: z.nativeEnum(GroupStatus),
  category: z.nativeEnum(GroupCategory),
  address: z.string()
      .optional().nullable(),
  notes: z.string()
      .optional().nullable(),
  email: z.string()
      .optional().nullable(),
  phone: z.string()
      .optional().nullable(),
})

type GroupFormData = z.infer<typeof groupFormSchema>

interface GroupDialogProps {
    open: boolean,
    setOpen: (open:boolean) => void,
    onSubmit: () => void,
    group: Group | null,
}
export function GroupDialog({open, setOpen, onSubmit, group}: GroupDialogProps) {
  const { 
      register, 
      handleSubmit,
      reset,
      formState: { errors, isDirty } 
  } = useForm<GroupFormData>({
      resolver: zodResolver(groupFormSchema)
  });
  const { t } = useTranslation()
  
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  
  const [openedAvatar, setOpenedAvatar] = useState<File | undefined>();
  const [sending, setSending] = useState(false);

  async function submit(data: GroupFormData) {
    setSending(true);
    try
    {
      if (group) {
        await api.put(`/groups/${group.id}`, {id: group.id, ...data});
      } else {
        await api.post(`/groups`, data);
      }
      if (openedAvatar) {
        const form = new FormData();
        form.append('file', openedAvatar);
        
        await api.put(`/groups/${group?.id}/photo`, form);
      }

      onSubmit();
    } catch {
      window.alert(t("groupsDialog.errorAlert"));
    }
    setSending(false);
  }

  useEffect(() => {
    if (group) {
      reset({
        name: group.name,
        avatar: group.avatar,
        status: group.status,
        category: group.category,
        address: group.address,
        notes: group.notes,
        phone: group.phone,
        email: group.email,
      })
    } else {
      reset({
        name: "",
        avatar: null,
        status: 1,
        category: 1,
        address: "",
        notes: "",
        phone: "",
        email: "",
      });
    }
  }, [group])

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Portal>
            <Dialog.Overlay className="bg-black/50 data-[state=open]:animate-overlayShow fixed inset-0" />
            <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
              <Dialog.Title className="text-mauve12 m-0 text-[17px] font-medium">
                {group ? t("groupsDialog.editGroup") : t("groupsDialog.newGroup")}
              </Dialog.Title>
              <Dialog.Description className="text-mauve11 mt-[10px] mb-5 text-[15px] leading-normal">
                {t("groupsDialog.description")}
              </Dialog.Description>
              <form onSubmit={handleSubmit(submit)}>
              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="name">
                  {t("groupsDialog.name")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  {...register("name")}
                />
              </fieldset>
              {errors?.name && <span className='text-red-600 w-full flex justify-end mb-4'>{t("groupsDialog." + errors?.name?.message!)}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="status">
                {t("groupsDialog.state")}
                </label>
                <select
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  {...register("status", { valueAsNumber: true })}
                >
                  {GroupStatusValues.map((status, index) => (
                    <option key={index} value={index + 1}>
                      {t("groupStatus." + status)}
                    </option>
                  ))}
                </select>
              </fieldset>
              {errors?.status && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.status?.message}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="category">
                {t("groupsDialog.category")}
                </label>
                <select
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  {...register("category", { valueAsNumber: true })}
                >
                  {GroupCategoryValues.map((category, index) => (
                    <option key={index} value={index + 1}>
                      {t("groupCategory." + category)}
                    </option>
                  ))}
                </select>
              </fieldset>
              {errors?.category && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.category?.message}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="address">
                {t("groupsDialog.address")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  type="text"
                  {...register("address")}
                />
              </fieldset>
              {errors?.address && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.address?.message}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="email">
                {t("groupsDialog.email")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  type="email"
                  {...register("email")}
                />
              </fieldset>
              {errors?.email && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.email?.message}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="phone">
                {t("groupsDialog.phone")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  type="phone"
                  {...register("phone")}
                />
              </fieldset>
              {errors?.phone && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.phone?.message}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="notes">
                {t("groupsDialog.notes")}
                </label>
                <textarea
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  {...register("notes")}
                />
              </fieldset>
              {errors?.notes && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.notes?.message}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="crm">
                {t("groupsDialog.photo")}
                </label>
                <input 
                    ref={inputFileRef}
                    type="file"
                    multiple={false} 
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => e.currentTarget?.files ? setOpenedAvatar(e.currentTarget.files[0]) : setOpenedAvatar(undefined)}
                />
                <img src={openedAvatar ? URL.createObjectURL(openedAvatar) : (group?.avatar ?? undefined)} alt="" className="h-12 w-12 rounded-full bg-zinc-300" />
                <button onClick={() => inputFileRef.current?.click()} type="button">{t("groupsDialog.change")}</button>
              </fieldset>

              <div className="mt-[25px] flex justify-end">
                <Button type='submit' disabled={!isDirty || sending}>
                  {sending ? t("groupsDialog.saving") : t("groupsDialog.save") }
                </Button>
              </div>
              </form>
              <Dialog.Close asChild>
                <button
                  className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                  aria-label="Close"
                >
                  <X />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      );
}
