// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translations: {
    time: {
      date: "DD/MM/YYYY",
      time: "HH:mm:ss",
      full: "DD/MM/YYYY - HH:mm:ss",
      at: " às "
    },
    groupStatus: {
      running: "Em andamento", 
      finished: "Finalizado", 
      stopped: "Parado",
    },
    groupCategory: {
      public: "Hospital público",
      private: "Hospital privado",
      clinic: "Clínica",
      others: "Outros"
    },
    userRole: {
      admin: "Administrador",
      coordinator: "Coordenador",
      manager: "Gerente",
      operator: "Operador"
    },
    login: {
      email: 'E-mail',
      password: "Senha",
      enter: "Entrar",
      entering: "Entrando",
      insertPassword: "Insira a senha",

      insertEmail: "Insira o e-mail",
      invalidPassword: "Senha inválida",
    },
    panel: {
      editGroup: "Editar %name%",
      editDescription: "Editar as informações do grupo selecionado"
    },
    resetPassword: {
      hi: "Olá, ",
      insertNewPassword: "Insira a nova senha",
      newPassword: "Nova senha",

      currentPassword: "Senha atual",
      insertCurrentPassword: "Insira a senha atual",

      insertConfirmPassword: "Insira a confirmação da nova senha",
      confirmPassword: "Confirme a senha",

      oldPassword: "Senha antiga",
      insertOldPassword: "Insira a antiga senha",

      passwordNotMatch: "As senhas não coincidem",
      createdPassword: "Senha criada, agora faça o login normalmente",
      requestError: "Falha na requisição",
      changePassword: "Alterar senha",
      changingPassword: "Alterando"
    },
    sidebar: {
      home: "Início",
      drive: "Arquivos",
      groups: "Grupos",
      users: "Usuários",
      patients: "Pacientes",
      guides: "Conteúdo",
      experts: "Especialistas",
      settings: "Configurações",
      exit: "Sair",
    },
    home: {
      drive: "Arquivos",
      users: "Usuários",
      patients: "Pacientes",
      guides: "Conteúdo",
      experts: "Especialistas",
    },
    drive: {
      title: "Arquivos",
      description: "Gerencie os arquivos",
      sendError: "Erro ao enviar arquivo, tente novamente",
      deleteAlert: "Você tem certeza que deseja excluir o arquivo '%name%'?",
      renameFile: "Renomear arquivo",
      delete: "Deletar",
      rename: "Renomear",
      import: "Importar",
      sending: "Enviando"
    },
    groups: {
      title: "Grupos",
      description: "Gerenciar grupos",
      new: "Novo Grupo",
      listUser: "Usuários",
      listState: "Estado",
      listInit: "Iniciado em"
    },
    groupsDialog: {
      description: "Clique em 'Salvar' para manter as alterações",
      editGroup: "Editar grupo",
      newGroup: "Novo grupo",
      errorAlert: "Ocorreu um erro ao enviar as informações para o servidor",
      insertName: "Insira um nome",
      minCharacters: "Insira no mínimo 3 caracteres",
      name: "Nome",
      state: "Estado",
      category: "Categoria",
      address: "Endereço",
      email: "E-mail",
      phone: "Telefone",
      notes: "Comentários",
      photo: "Foto",
      change: "Alterar",
      saving: "Salvando",
      save: "Salvar"
    },
    users: {
      description: "Gerencie os usuários do grupo",
      new: "Novo usuário",
      listAccesses: "Acessos",
      listAccessesNumber: "Acesso(s)",
      listEmail: "E-mail",
      listPhone: "Telefone",
      listRegistration: "Cadastrado em",
      deleteDialog: "Você tem certeza que deseja remover o usuário '%name%'?\nIMPORTANTE, todas as informações relacionadas a este usuário serão deletadas, incluindo os dados de consumo!"
    },
    usersDialog: {
      description: "Clique em 'Salvar' para manter as alterações",
      editUser: "Editar usuário",
      newUser: "Novo usuário",
      name: "Nome",
      minCharacters: "Insira no mínimo 3 caracteres.",
      insertName: "Insira um nome",
      phone: "Telefone",
      email: "E-mail",
      insertEmail: "Insira um e-mail",
      crm: "CRM",
      role: "Cargo",
      linkedin: "Perfil",
      notes: "Comentários",
      permissions: "Permissões",
      photo: "Foto",
      change: "Alterar",
      newPassword: "Gerar nova senha",
      passwordTempAlert: "Senha temporária copiada!",
      saving: "Salvando",
      save: "Salvar"
    },
    userInteraction: {
      title: "Interações",
      description: "Visualizar os dados de consumo de %name%",
      noteUpdateError: "Falha ao atualizar comentário",
      listSave: "Salvar",
      listStep: "Etapa",
      listDuration: "Duração",
      listResponse: "Resposta",
      listDiagnostic: "Diagnóstico",
      listGuide: "Conteúdo",
      listPatient: "Paciente",
      listStartedAt: "Iniciado em" ,
      listUpload: "Upload",
      listNotes: "Comentários",
      listMainInformation: "Informações principais",
      listEndedAt: "Encerrado em",
      deleteAlert: "Você tem certeza que deseja deletar essa interação?",
    },
    patients: {
      description: "Gerencie a lista de pacientes",
      new: "Novo paciente",
      listInteractions: "Interações",
      listNoInteractions: "Nenhuma interação",
      listOneInteractions: "1 interação",
      listNInteractions: "%n% interações",
      listRegistration: "Cadastro",
      deleteAlert: "Você tem certeza que deseja remover o paciente '%name%'?\nIMPORTANTE, todas as informações relacionadas ao este paciente serão deletadas, incluindo os dados de consumo!"
    },
    patientsDialog: {
      editPatient: "Editar paciente",
      newPatient: "Novo paciente",
      description: "Clique em 'Salvar' para manter as alterações",
      name: "Nome",
      minCharacters: "Insira no mínimo 3 caracteres.",
      insertName: "Insira um nome",
      mothersName: "Nome da mãe",
      idNumber: "ID Number",
      daysLife: "Dias de vida",
      notes: "Comentários",
      saving: "Salvando",
      save: "Salvar"
    },
    patientInteraction: {
      title: "Interações",
      description: "Visualize os resultados de exame relacionado ao paciente '%name%'",
      print: "Imprimir",
      listGuide: "Exame",
      listUser: "Usuário",
      listStartedAt: "Iniciado em" ,
      listUpload: "Upload",
      deleteAlert: "Você tem certeza que deseja deletar essa informação?",
      printFailed: "Ocorreu um erro ao imprimir os resultados de exame, tente novamente"
    },
    guides: {
      description: "Gerencie a lista de conteúdo do grupo",
      name: "Nome",
      enabled: "Habilitado"
    },
    experts: {
      description: "Gerencie a lista de especialistas do grupo",
      new: "Novo contato",
      deleteAlert: "Você tem certeza que deseja deletar o especialista '%name%'?"
    },
    expertsDialog: {
      description: "Clique em 'Salvar' para manter as alterações",
      editExpert: "Editar contato",
      newExpert: "Novo contato",
      name: "Nome",
      minCharacters: "Insira no mínimo 3 caracteres.",
      insertName: "Insira um nome",
      insertObject: "Insira o Object ID",
      role: "Cargo",
      photo: "Foto",
      change: "Alterar",
      saving: "Salvando",
      save: "Salvar"
    },
    settings: {
      description: "Clique em 'Salvar' para manter as alterações",
      name: "Nome",
      minCharacters: "Insira no mínimo 3 caracteres.",
      insertName: "Insira um nome",
      state: "Estado",
      category: "Categoria",
      address: "Endereço",
      email: "E-mail",
      link: "Link sala virtual",
      operatorEmail: "E-mail do operador",
      phone: "Telefone",
      notes: "Comentários",
      delete: "Deletar",
      deleteAlert: "Você tem certeza que deseja deletar o grupo '%name%'?\n\nIMPORTANTE, todos os usuários com suas informações serão excluídos!",
      photo: "Foto",
      change: "Alterar",
      sendError: "Ocorreu um erro ao enviar as informações para o servidor",
      saving: "Salvando",
      save: "Salvar"
    }
  }
}