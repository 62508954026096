import { clsx } from "clsx";
import { useTranslation } from "react-i18next";
import { useGroupStore } from "stores/useGroup";

interface MainLayoutGroupPageProps
{
  children: React.ReactNode,
  actionButton?: React.ReactNode,
  subtitle?: string,
}
export function MainLayoutGroupPage({children, subtitle, actionButton}: MainLayoutGroupPageProps) {
  const group = useGroupStore(state => state.group);
  const { t } = useTranslation()
  return (
    <main className="prose w-full mx-auto max-w-5xl">
      <div className="flex justify-between mb-10">
        <div>
          <h2 className="m-0">{t("panel.editGroup").replace("%name%", group?.name!)}</h2>
          <p className="m-0">{subtitle ?? t("panel.editDescription")}</p>
        </div>
        {actionButton && <div className="flex items-center">
            {actionButton}
        </div>}
      </div>
      <div
        className={clsx(
          "flex flex-col rounded-t-md border border-zinc-200 bg-white border-b"
        )}
      >
        {children}
      </div>
    </main>
  );
}