/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "services/api";
import { User } from "models/user";
import { Interaction } from "models/interaction";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
import relative from 'dayjs/plugin/relativeTime';
import { convertDate } from "utils/dateUtils";
import { ChevronDown, ChevronUp, Loader2, Trash } from "lucide-react";
import 'dayjs/locale/pt-br' // carregar sob demanda
import { Button } from "components/Button";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useUser } from "stores/useUser";

dayjs.locale('pt-br') // usar locale espanhol globalmente
dayjs.extend(duration)
dayjs.extend(relative)

interface InteractionViewProps {
  interaction: Interaction;
  onDelete: () => void;
  onUpdate: () => void;
}
function InteractionView({interaction, onDelete, onUpdate}: InteractionViewProps) {
  const [expanded, setExpanded] = useState(false);
  const createDate = dayjs(convertDate(interaction.createdAt).valueOf());
  const started = dayjs(interaction.startedTime);
  const [notes, setNotes] = useState(interaction.notes);
  const [sending, setSending] = useState(false);
  const { t } = useTranslation()
  const can = useUser((state) => state.can);

  function updateNotes()
  {
    setSending(true);
    api.put(`/interactions/${interaction.id}`, {
      notes
    })
    .then(onUpdate)
    .catch(() => alert(t("userInteraction.noteUpdateError")))
    .finally(() => setSending(false));
  }

  return (
    <div key={interaction.id} className="bg-white group flex flex-col p-4 border border-b-0 last:border-b first:rounded-t-md last:rounded-b-md border-zinc-200">
      <div className="flex gap-10 relative overflow-hidden">
        <div className="flex flex-col w-64 flex-shrink-0">
            <span className="text-xs text-zinc-400">{t("userInteraction.listGuide")}</span>
            {interaction.guide?.title}
        </div>
        {interaction.patient ? <div className="flex flex-col w-64 flex-shrink-0">
            <span className="text-xs text-zinc-400">{t("userInteraction.listPatient")}</span>
            {interaction.patient?.name}
        </div> : <span className="w-64"/>}
        <div className="flex flex-col flex-shrink-0">
            <span className="text-xs text-zinc-400">{t("userInteraction.listStartedAt")}</span>
            {started.format(t("time.date"))}{t("time.at")}{started.format(t("time.time"))}
        </div>
        <div className="flex flex-col flex-shrink-0">
            <span className="text-xs text-zinc-400">{t("userInteraction.listUpload")}</span>
            {dayjs().from(createDate, true)}
        </div>
        <div className="flex absolute right-0 bg-white h-full">
          {can(["coordinator", "admin"]) && <button onClick={onDelete} className="p-2 rounded invisible group-hover:visible hover:bg-red-200 hover:text-red-500 text-zinc-800">
              <Trash size={20} />
          </button>}
          <button onClick={_=>setExpanded(!expanded)} className="p-2 rounded  hover:bg-zinc-200 text-zinc-800">
              {expanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </button>
        </div>
      </div>
      {expanded && <div className="border-t border-border mt-2 flex flex-col h-full">
        <table>
          <thead>
            <tr>
              <th>{t("userInteraction.listNotes")}</th>
            </tr>
          </thead>
          <tbody className="flex flex-col justify-end items-end py-2 gap-2">
            <textarea onChange={(e) => setNotes(e.currentTarget.value)} className="w-full border border-zinc-300">
              {notes}
            </textarea>
            <Button onClick={updateNotes} disabled={notes === interaction.notes || sending}>
              {sending
              ?<Loader2 className="animate-spin" />
              :t("userInteraction.listSave")}
            </Button>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>{t("userInteraction.listMainInformation")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
              {t("userInteraction.listStartedAt")}
              </td>
              <td>
              {dayjs(interaction.startedTime).format(t("time.full"))}
              </td>
            </tr>
            <tr>
              <td>
              {t("userInteraction.listEndedAt")}
              </td>
              <td>
              {dayjs(interaction.finishedTime).format(t("time.full"))}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>{t("userInteraction.listStep")}</th>
              <th>{t("userInteraction.listDuration")}</th>
              <th className="w-96">{t("userInteraction.listResponse")}</th>
            </tr>
          </thead>
          <tbody>
            {
              interaction.stepInteractions.map(step => (
                <tr key={step.stepGuid}>
                  <td>
                    {step.stepTitle}
                  </td>
                  <td>
                  {dayjs.duration(step.duration).format("HH:mm:ss")}s
                  </td>
                  <td>
                    {step.formResponse !== -1 && <>
                      <p className="font-bold m-0">{step.formResponseTitle}</p>
                      <span className="text-sm m-0 opacity-50">{step.formResponseDescription}</span>
                  </>}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>{t("userInteraction.listDiagnostic")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{interaction.diagnostic.replace(`<style="bold">`, '').replace(`</style>`, '')}</td>
            </tr>
          </tbody>
        </table>
      </div>}
    </div>
  )
}
export function UserPage() {
  const { userId } = useParams();
  const [user, setUser] = useState<User | null>(null);
  const { t } = useTranslation()

  function deleteInteraction(interaction: Interaction) {
    if (confirm(t("userInteraction.deleteAlert")))
    {
      api.delete(`/interactions/${interaction.id}`)
        .then(reload);
    }
  }
  function reload() {
    if (userId) {
      api.get(`/users/${userId}`).then(async (res) => {
        let user = res.data;
        let interactions = [];

        for(const interaction of user.interactions) {
          const res = await axios.get(interaction.url);
          const stepInteractions = res.data.stepInteractions.map((s:any) => 
          {
            const startedTime = convertDate(s.startedTime).valueOf();
            const finishedTime = convertDate(s.finishedTime).valueOf();
            return {
              ...s, 
              startedTime,
              finishedTime,
              duration: dayjs(finishedTime).diff(dayjs(startedTime))
            }
          });
          interactions.push({
            ...res.data, 
            id: interaction.id, 
            guid: interaction.guid,
            guide: interaction.guide,
            patient: interaction.patient,
            createdAt: interaction.createdAt,
            notes: interaction.notes,
            stepInteractions,
            startedTime: stepInteractions[0].startedTime,
            finishedTime: stepInteractions[stepInteractions.length - 1].finishedTime,
          });
          console.log(interactions)
        }

        user.interactions = interactions;
        setUser(res.data)
      });
    }
  }
  useEffect(reload, []);

  if (user === null) {
    return (
      <main className="prose w-full mx-auto max-w-5xl">
        <div className="flex justify-between">
          <div>
            <div className="h-6 w-96 rounded bg-zinc-300 animate-pulse" />
            <div className="mt-2 h-6 w-32 rounded bg-zinc-300 animate-pulse" />
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className="prose w-full mx-auto max-w-5xl">
      <div className="flex justify-between mb-10">
        <div>
          <h2 className="m-0">{t("userInteraction.title")}</h2>
          <p className="m-0">{t("userInteraction.description").replace("%name%", user.name)}</p>
        </div>
      </div>
      <div className="flex flex-col">
        {user.interactions?.map(interaction => <InteractionView interaction={interaction} onUpdate={reload} onDelete={()=>deleteInteraction(interaction)} />)}
      </div>
    </main>
  );
}
