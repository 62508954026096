import { LucideIcon } from 'lucide-react';
import { create } from 'zustand'

export interface ContextMenuItem {
  title: string,
  onClick: () => void,
  icon?: LucideIcon,
  disabled?: boolean
}
interface Point {
  x: number,
  y: number
}
export interface ContextMenu {
  point: Point,
  items: ContextMenuItem[]
}

interface ContextMenuState {
  menu: ContextMenu | null,
  close: () => void,
  open: (point: Point, items: ContextMenuItem[]) => void,
}

export const useContextMenu = create<ContextMenuState>()((set) => ({
  menu: null,
  close: () => set((state) => ({menu: null})),
  open: (point, items) => set((state) => ({ menu: {
    point,
    items
  }})),
}))