import dayjs from 'dayjs';
const sqlTimeFormat = "YYYY-MM-DDTHH:mm:ss.000Z";

export const months = (index: string) => {
    return {
        "1": "JAN",
        "2": "FEV",
        "3": "MAR",
        "4": "ABR",
        "5": "MAI",
        "6": "JUN",
        "7": "JUL",
        "8": "AGO",
        "9": "SET",
        "10": "OUT",
        "11": "NOV",
        "12": "DEZ",
    }[index]
}

export function convertDate(str:string) {
    return dayjs(str, sqlTimeFormat);
}
export function stringfyDate(date: dayjs.Dayjs) {
    return date.format(sqlTimeFormat);
}