/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import api from "services/api";
import * as Switch from "@radix-ui/react-switch";
import { Guide } from "models/guide";
import { useGroupStore } from "stores/useGroup";
import { useUser } from "stores/useUser";
import { useNavigate } from "react-router-dom";
import { MainLayoutGroupPage } from "./mainLayout";
import { useTranslation } from "react-i18next";

export function GroupGuidePage() {
  const group = useGroupStore(state => state.group);
  const user = useUser(state => state.user);
  const can = useUser(state => state.can);
  const reload = useGroupStore(state => state.reload);
  const [guides, setGuides] = useState<Guide[]>([]);
  const [sending, setSending] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation()

  function handleUser()
  {
      if (user)
      {
          if (can(["admin"]) === false)
          {
              navigate(`/grupos/${user?.groupId}`);
              return;
          }
      }
  }
  useEffect(handleUser, [user]);
  async function handleSwitch(guide: Guide, value: boolean) {
    setSending(true);
    if (value) {
      await api.post(`/groups/${group?.id}/guides`, {
        id: guide.id,
      });
    } else {
      await api.delete(`/groups/${group?.id}/guides/${guide.id}`);
    }
    reload();
    setSending(false);
  }
  function reloadGuides() {
    if (group) {
      api.get("/guides").then((res) => setGuides(res.data));
    }
  }
  useEffect(reloadGuides, []);

  if (group === null)
    return <></>;
    
  const groupGuides = group.guides.map((guide) => guide.id);

  return (
    <MainLayoutGroupPage subtitle={t("guides.description")}>
      <table className="m-0">
        <thead>
          <tr className="">
            <th className="p-5">{t("guides.name")}</th>
            <th className="p-5">{t("guides.enabled")}</th>
          </tr>
        </thead>
        <tbody>
          {guides.map((guide) => (
            <tr key={guide.id} className="hover:bg-zinc-100 group">
              <td className="p-3 align-middle">
                <span className="flex gap-2">{guide.title}</span>
              </td>
              <td className="p-3 align-middle">
                <Switch.Root
                  className="cursor-pointer w-[42px] h-[25px] bg-zinc-400 disabled:opacity-20 rounded-full relative data-[state=checked]:bg-primary outline-none"
                  disabled={sending}
                  checked={groupGuides.includes(guide.id)}
                  onCheckedChange={(v) => handleSwitch(guide, v)}
                >
                  <Switch.Thumb className="block w-[20px] h-[20px] bg-white rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
                </Switch.Root>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </MainLayoutGroupPage>
  );
}
