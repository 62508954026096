import { Button } from "components/Button";
import { Form } from "components/Form";
import api from "services/api";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { useUser } from "stores/useUser";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { UserToken } from "models/userToken";
import { useTranslation } from "react-i18next";

const resetPasswordFormSchema = z.object({
    oldPassword: z.string()
        .nonempty("resetPassword.insertCurrentPassword"),
    password: z.string()
        .nonempty("resetPassword.insertNewPassword"),
    confirmPassword: z.string()
        .nonempty("resetPassword.insertConfirmPassword"),
}).refine((data) => data.password === data.confirmPassword, {
    message: "resetPassword.passwordNotMatch",
    path: ["confirmPassword"],
});

type ResetPasswordFormData = z.infer<typeof resetPasswordFormSchema>

export default function ResetPasswordPage() {
    
    const { 
        register, 
        handleSubmit,
        setError, 
        formState: { errors, isDirty } 
    } = useForm<ResetPasswordFormData>({
        resolver: zodResolver(resetPasswordFormSchema)
    });
    const router = useNavigate();
    const [sending, setSending] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const user = useUser(state => state.user);
    const setUser = useUser(state => state.setUser);
    const navigate = useNavigate();
    const { t } = useTranslation()

    async function handleReset({oldPassword, password, confirmPassword}: ResetPasswordFormData) {
        setSending(true);
        try {
            const res = await api.post("/auth/resetPassword", {
                email: user?.email,
                oldPassword,
                password,
                confirmPassword
            })
            setUser(null);
            alert(t("resetPassword.createdPassword"))
            removeCookie("token");
            router("/login")
        } catch (e:any) {
            console.error(e);
            setError("confirmPassword", {message: t("resetPassword.requestError")});
        } finally {
            setSending(false);
        }
    }
    
    function handleUser() {
        const user:any = jwtDecode<UserToken>(cookies["token"]);
        setUser(user);
        if (user === null) {
            navigate(`/login`);
            return;
        }
    }
    useEffect(handleUser, []);
    
    if (user === null)
        return <></>;

    return (
        <body>
            <main className="prose w-screen h-screen flex justify-center items-center max-w-none bg-zinc-300 bg-login bg-cover">
                <form onSubmit={handleSubmit(handleReset)} className="flex flex-col gap-4 drop-shadow bg-white p-6 rounded-md items-center">
                    <div>
                        {t("resetPassword.hi")}{user.name}
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <Form.Label htmlFor="oldPassword">{t("resetPassword.oldPassword")}</Form.Label>
                        <Form.InputPassword placeholder={t("resetPassword.insertOldPassword")} register={register} label="oldPassword"/>
                        {errors.oldPassword && <span>{t(errors.oldPassword?.message!)}</span>}
                    </div>
                    <div className="flex flex-col gap-1">
                        <Form.Label htmlFor="password">{t("resetPassword.newPassword")}</Form.Label>
                        <Form.InputPassword placeholder={t("resetPassword.insertNewPassword")} register={register}/>
                        {errors.password && <span>{t(errors.password?.message!)}</span>}
                    </div>
                    <div className="flex flex-col gap-1">
                        <Form.Label htmlFor="confirmPassword">{t("resetPassword.confirmPassword")}</Form.Label>
                        <Form.InputPassword placeholder={t("resetPassword.insertConfirmPassword")} register={register} label="confirmPassword"/>
                        {errors.confirmPassword && <span>{t(errors.confirmPassword?.message!)}</span>}
                    </div>
                    <Button type="submit" disabled={!isDirty} className="w-full" loadingText={sending && t("resetPassword.changingPassword")}>
                        {t("resetPassword.changePassword")}
                    </Button>
                </form>
            </main>
        </body>
    )
}