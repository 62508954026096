import React, { useEffect, useRef, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { Button } from 'components/Button';
import { z } from 'zod';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from 'react-hook-form';
import api from 'services/api';
import { Group } from 'models/group';
import { Patient } from 'models/patient';
import { useTranslation } from 'react-i18next';

const patientFormSchema = z.object({
  avatar: z.any(),
  name: z.string()
      .nonempty("insertName")
      .min(3, "minCharacters"),
  mothersName: z.string()
      .optional().nullable(),
  daysLife: z.number()
      .optional().nullable(),
  idNumber: z.string()
      .optional().nullable(),
  notes: z.string()
      .optional().nullable(),
  enabled: z.boolean()
      .optional().nullable(),
})

type PatientFormData = z.infer<typeof patientFormSchema>

interface PatientDialogProps {
    open: boolean,
    setOpen: (open:boolean) => void,
    onSubmit: () => void,
    patient: Patient | null,
    group: Group,
}
export function PatientDialog({open, setOpen, onSubmit, patient, group}: PatientDialogProps) {
  const { 
      register, 
      handleSubmit,
      reset,
      formState: { errors, isDirty } 
  } = useForm<PatientFormData>({
      resolver: zodResolver(patientFormSchema)
  });
  const [sending, setSending] = useState(false);
  const { t } = useTranslation()

  async function submit(data: PatientFormData) {
    setSending(true);

    let currentPatient = patient;

    if (currentPatient) {
      await api.put(`/patients/${currentPatient.id}`, {id: currentPatient.id, ...data});
    } else {
      const res = await api.post(`/groups/${group.id}/patients`, {...data});
      currentPatient = res.data;
    }
    setSending(false);
    onSubmit();
  }

  useEffect(() => {
    if (patient) {
      reset({
        name: patient.name,
        mothersName: patient.mothersName,
        daysLife: patient.daysLife,
        idNumber: patient.idNumber,
        notes: patient.notes,
      })
    } else {
      reset({
        name: "",
        mothersName: undefined,
        daysLife: undefined,
        idNumber: "",
        notes: "",
        enabled: true,
      });
    }
  }, [patient, open])

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Portal>
            <Dialog.Overlay className="bg-black/50 data-[state=open]:animate-overlayShow fixed inset-0" />
            <Dialog.Content className="overflow-y-auto data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
              <Dialog.Title className="text-mauve12 m-0 text-[17px] font-medium">
                {patient ? t("patientsDialog.editPatient") : t("patientsDialog.newPatient")}
              </Dialog.Title>
              <Dialog.Description className="text-mauve11 mt-[10px] mb-5 text-[15px] leading-normal">
                {t("patientsDialog.description")}
              </Dialog.Description>
              <form onSubmit={handleSubmit(submit)}>
              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="name">
                {t("patientsDialog.name")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  {...register("name")}
                />
              </fieldset>
              {errors?.name && <span className='text-red-600 w-full flex justify-end mb-4'>{t("patientsDialog." + errors?.name?.message)}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="mothersName">
                {t("patientsDialog.mothersName")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  {...register("mothersName")}
                />
              </fieldset>
              {errors?.mothersName && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.mothersName?.message}</span>}
              
              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="idNumber">
                {t("patientsDialog.idNumber")}
                </label>
                <input
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  {...register("idNumber")}
                />
              </fieldset>
              {errors?.idNumber && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.idNumber?.message}</span>}
              
              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="daysLife">
                {t("patientsDialog.daysLife")}
                </label>
                <input
                  type="number"
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  {...register("daysLife", {
                    setValueAs: (v) => v === "" ? undefined : parseInt(v, 10),
                  })}
                />
              </fieldset>
              {errors?.daysLife && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.daysLife?.message}</span>}

              <fieldset className="mb-[15px] flex items-center gap-5">
                <label className="w-[90px] text-right text-[15px]" htmlFor="notes">
                {t("patientsDialog.notes")}
                </label>
                <textarea
                  className="border border-zinc-400 focus:border-black inline-flex h-[35px] w-full flex-1 items-center justify-center px-[10px] text-[15px] leading-none outline-none"
                  {...register("notes")}
                />
              </fieldset>
              {errors?.notes && <span className='text-red-600 w-full flex justify-end mb-4'>{errors?.notes?.message}</span>}

              <div className="mt-[25px] flex justify-end">
                <Button type='submit' disabled={!isDirty || sending}>
                 {sending ? t("patientsDialog.saving") : t("patientsDialog.save")}
                </Button>
              </div>
              </form>
              <Dialog.Close asChild>
                <button
                  className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                  aria-label="Close"
                >
                  <X />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      );
}
