/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import { User } from "models/user";
import { Button } from "components/Button";
import {
  LineChart,
  Loader2,
  Pen,
  PlusIcon,
  Trash,
  UserIcon,
} from "lucide-react";
import dayjs from "dayjs";
import { UserDialog } from "./UserDialog";
import { useGroupStore } from "stores/useGroup";
import { useUser } from "stores/useUser";
import { MainLayoutGroupPage } from "./mainLayout";
import { useTranslation } from "react-i18next";

export function GroupUserPage() {
  const { t } = useTranslation()
  const group = useGroupStore((state) => state.group);
  const reload = useGroupStore((state) => state.reload);
  const can = useUser((state) => state.can);
  const loggedUser = useUser((state) => state.user);
  const [userDialog, setUserDialog] = useState(false);
  const [editUser, setEditUser] = useState<User | null>(null);
  const navigate = useNavigate();

  function handleUser()
  {
      if (loggedUser)
      {
          if (can(["admin", "manager", "coordinator"]) === false)
          {
              navigate(`/grupos/${loggedUser?.groupId}`);
              return;
          }
      }
  }
  useEffect(handleUser, [loggedUser]);

  function handleSubmitUser() {
    setUserDialog(false);
    setEditUser(null);
    reload();
  }

  function handleEditUser(user: User) {
    setEditUser(user);
    setUserDialog(true);
  }

  function handleNewUser() {
    setEditUser(null);
    setUserDialog(true);
  }

  function handleRemoveUser(user: User) {
    if (
      confirm(
        t("users.deleteDialog").replace("%name%", user.name)
      )
    ) {
      api.delete(`/users/${user.id}`).then(reload);
    }
  }
  if (group === null) return <></>;
  return (
    <MainLayoutGroupPage
      subtitle={t("users.description")}
      actionButton={
        <Button onClick={handleNewUser}>
          <PlusIcon size={16} />
          {t("users.new")}
        </Button>
      }
    >
      <table className="m-0">
        <tbody>
          {group.users.map((user) => (
            <tr key={user.id} className="hover:bg-zinc-100 group">
              <td className="p-3 align-middle">
                <span className="flex gap-2 items-center">
                  {user.avatar ? (
                    <span className="relative h-12 w-12 rounded-full bg-zinc-300 flex justify-center items-center text-zinc-400 overflow-hidden">
                      <Loader2 className="animate-spin" />
                      <img
                        src={user.avatar}
                        alt=""
                        className="m-0 h-full w-full absolute"
                      />
                    </span>
                  ) : (
                    <span className="h-12 w-12 rounded-full bg-zinc-300 flex justify-center items-center text-zinc-400">
                      <UserIcon />
                    </span>
                  )}
                  <div className="flex flex-col">
                    <span className="font-bold">{user.name}</span>
                    <span className="text-xs text-zinc-400">{user.roles.map(r => t("userRole." + r.name)).join(", ")}</span>
                  </div>
                </span>
              </td>
              <td className="p-3 align-middle">
                <div className="flex flex-col">
                  <span className="text-xs text-zinc-400">{t("users.listAccesses")}</span>
                  {user.interactions?.length} {t("users.listAccessesNumber")}
                </div>
              </td>
              <td className="p-3 align-middle">
                <div className="flex flex-col">
                  <span className="text-xs text-zinc-400">{t("users.listEmail")}</span>
                  {user.email}
                </div>
              </td>
              <td className="p-3 align-middle">
                <div className="flex flex-col">
                  <span className="text-xs text-zinc-400">{t("users.listPhone")}</span>
                  {user.phone}
                </div>
              </td>
              <td className="p-3 align-middle">
                <div className="flex flex-col">
                  <span className="text-xs text-zinc-400">{t("users.listRegistration")}</span>
                  {dayjs(user.createdAt).format(t("time.date"))}
                </div>
              </td>
              <td className="p-3 align-middle">{user.crm}</td>
              <td className="p-3 align-middle text-right">
                <div className="invisible group-hover:visible">
                  {loggedUser?.id != user.id && user.interactions.length === 0 && <button
                    onClick={(_) => handleRemoveUser(user)}
                    className="p-2 rounded text-zinc-800 hover:bg-red-200 hover:text-red-500"
                  >
                    <Trash size={20} />
                  </button>}
                  <button
                    onClick={(_) => handleEditUser(user)}
                    className="p-2 rounded hover:bg-zinc-200 text-zinc-800"
                  >
                    <Pen size={20} />
                  </button>
                  <button
                    onClick={(_) =>
                      navigate(`/grupos/${group.id}/usuarios/${user.id}`)
                    }
                    className="p-2 rounded hover:bg-zinc-200 text-zinc-800"
                  >
                    <LineChart size={20} />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <UserDialog
        user={editUser}
        open={userDialog === true}
        setOpen={setUserDialog}
        group={group}
        onSubmit={handleSubmitUser}
      />
    </MainLayoutGroupPage>
  );
}
