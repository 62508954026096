import clsx from "clsx";
import { InputHTMLAttributes, ReactNode } from "react"
import { FieldValues, UseFormRegister } from "react-hook-form";

interface InputProps extends InputHTMLAttributes<HTMLInputElement>
{
    children?: ReactNode;
    className?: string;
    register: UseFormRegister<any>;
    label: string;
    multiline?: boolean;
}
export const Input = ({children, className, label, register, multiline, ...props}: InputProps) => {
    return (
        <div className="relative">
            {multiline 
            ? <textarea
                className={
                    clsx(
                        "placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-3 px-4 shadow-sm focus:outline-none focus:border-black focus:ring-black/20 focus:ring-1 sm:text-sm",
                        className,
                    )
                }
                {...register(label)}
            />
            :<input
                className={
                    clsx(
                        "placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-3 px-4 shadow-sm focus:outline-none focus:border-black focus:ring-black/20 focus:ring-1 sm:text-sm",
                        className,
                    )
                }
                {...props}
                {...register(label, {valueAsNumber: props?.type === "number" })}
            />}
            {children}
        </div>
    )
}