/* eslint-disable no-restricted-globals */
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import api from "services/api";
import { User } from "models/user";
import { Interaction } from "models/interaction";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
import relative from 'dayjs/plugin/relativeTime';
import { convertDate } from "utils/dateUtils";
import { ChevronDown, ChevronUp, FileDownIcon, Loader2, PrinterIcon, Trash } from "lucide-react";
import 'dayjs/locale/pt-br' // carregar sob demanda
import { Button } from "components/Button";
import axios from "axios";
import { Patient } from "models/patient";
import { useUser } from "stores/useUser";
import { useTranslation } from "react-i18next";

dayjs.locale('pt-br') // usar locale espanhol globalmente
dayjs.extend(duration)
dayjs.extend(relative)

interface InteractionViewProps {
  interaction: Interaction;
  onDelete: () => void;
}
function InteractionView({interaction, onDelete}: InteractionViewProps) {
  const createDate = dayjs(convertDate(interaction.createdAt).valueOf());
  const started = dayjs(interaction.startedTime);
  const can = useUser((state) => state.can);
  const { t } = useTranslation()


  return (
    <div key={interaction.id} className="bg-white group flex flex-col p-4 border border-b-0 last:border-b first:rounded-t-md last:rounded-b-md border-zinc-200">
      <div className="flex gap-10 relative overflow-hidden">
        <div className="flex flex-col w-64 flex-shrink-0">
            <span className="text-xs text-zinc-400">{t("patientInteraction.listGuide")}</span>
            {interaction.guide?.title}
        </div>
        {interaction.user && <div className="flex flex-col w-64 flex-shrink-0">
            <span className="text-xs text-zinc-400">{t("patientInteraction.listUser")}</span>
            {interaction.user?.name}
        </div>}
        <div className="flex flex-col flex-shrink-0">
            <span className="text-xs text-zinc-400">{t("patientInteraction.listStartedAt")}</span>
            {started.format(t("time.full"))}
        </div>
        <div className="flex flex-col flex-shrink-0">
            <span className="text-xs text-zinc-400">{t("patientInteraction.listUpload")}</span>
            {dayjs().from(createDate, true)}
        </div>
        <div className="flex absolute right-0 bg-white h-full">
          {can(["admin", "manager", "coordinator"]) && <button onClick={onDelete} className="p-2 rounded invisible group-hover:visible hover:bg-red-200 hover:text-red-500 text-zinc-800">
              <Trash size={20} />
          </button>}
        </div>
      </div>
    </div>
  )
}
export function PatientPage() {
  const { patientId } = useParams();
  const [patient, setPatient] = useState<Patient | null>(null);
  const [pending, setPending] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { t } = useTranslation()
  
  function downloadInteractionPDF()
  {
    setPending(true);
    
    api({
      url: `/patients/${patient?.id}/interactions`, //your url
      method: 'GET',
      //responseType: 'blob', // important
    }).then((response) => {
        // create file link in browser's memory

        iframeRef.current!.srcdoc = response.data;
        setTimeout(function() {
          iframeRef.current!.focus();
          iframeRef.current!.contentWindow!.print();
          setPending(false)
        }, 500);
    }).catch(() => {
      alert(t("patientInteraction.printFailed"));
      setPending(false)
    })
  }

  function deleteInteraction(interaction: Interaction) {
    if (confirm(t("patientInteraction.deleteAlert")))
    {
      api.delete(`/interactions/${interaction.id}`)
        .then(reload);
    }
  }
  function reload() {
    if (patientId) {
      api.get(`/patients/${patientId}`).then(async (res) => {
        let patient = res.data;
        let interactions = [];

        for(const interaction of patient.interactions) {
          const res = await axios.get(interaction.url);
          const stepInteractions = res.data.stepInteractions.map((s:any) => 
          {
            const startedTime = convertDate(s.startedTime).valueOf();
            const finishedTime = convertDate(s.finishedTime).valueOf();
            return {
              ...s, 
              startedTime,
              finishedTime,
              duration: dayjs(finishedTime).diff(dayjs(startedTime))
            }
          });
          interactions.push({
            ...res.data, 
            id: interaction.id, 
            guid: interaction.guid,
            guide: interaction.guide,
            user: interaction.user,
            createdAt: interaction.createdAt,
            notes: interaction.notes,
            patient,
            stepInteractions,
            startedTime: stepInteractions[0].startedTime,
            finishedTime: stepInteractions[stepInteractions.length - 1].finishedTime,
          });
        }

        patient.interactions = interactions;
        setPatient(res.data)
      });
    }
  }
  useEffect(reload, []);

  if (patient === null) {
    return (
      <main className="prose w-full mx-auto max-w-5xl">
        <div className="flex justify-between">
          <div>
            <div className="h-6 w-96 rounded bg-zinc-300 animate-pulse" />
            <div className="mt-2 h-6 w-32 rounded bg-zinc-300 animate-pulse" />
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className="prose w-full mx-auto max-w-5xl">
      <iframe ref={iframeRef} title={`Prontuário ${patient.name}`} style={{display: "none"}} />
      <div className="flex justify-between mb-10">
        <div>
          <h2 className="m-0">{t("patientInteraction.title")}</h2>
          <p className="m-0">{t("patientInteraction.description").replace("%name%", patient.name)}</p>
        </div>
        <div className="flex items-center">
            <Button disabled={pending} onClick={downloadInteractionPDF}>
                {pending ? <Loader2 size={16} className="animate-spin" /> : <PrinterIcon size={16}/>}
                {t("patientInteraction.print")}
            </Button>
        </div>
      </div>
      <div className="flex flex-col">
        {patient.interactions.map(interaction => <InteractionView interaction={interaction} onDelete={()=>deleteInteraction(interaction)} />)}
      </div>
    </main>
  );
}
