import { create } from 'zustand'
import { Group } from "models/group"
import api from 'services/api'

interface GroupState {
    group: Group | null
    setGroup: (group: Group|null) => void
    load: (id: number) => void
    reload: () => void
}

export const useGroupStore = create<GroupState>()(
    (set, get) => ({
        group: null,
        setGroup: (group:Group|null) => set((state) => ({group})),
        load: (id: number) => {
            api.get(`/groups/${id}`).then((res) => get().setGroup(res.data));
        },
        reload: () => {
            const group = get().group;
            if (group != null)
                get().load(group.id);
        }
    }),
)