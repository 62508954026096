// eslint-disable-next-line import/no-anonymous-default-export
export default {
    translations: {
      time: {
        date: "MM/DD/YYYY",
        time: "HH:mm:ss",
        full: "MM/DD/YYYY - HH:mm:ss",
        at: " at "
      },
      groupStatus: {
        running: "Running", 
        finished: "Finished", 
        stopped: "Stopped",
      },
      groupCategory: {
        public: "Public Hospital",
        private: "Privado Hospital",
        clinic: "Clinic",
        others: "Others"
      },
      userRole: {
        admin: "Administrator",
        coordinator: "Coordinator",
        manager: "Manager",
        operator: "Examiner"
      },
      login: {
        email: 'E-mail',
        password: "Password",
        enter: "Enter",
        entering: "Entering",
        insertPassword: "Enter password",

        insertEmail: "Enter e-mail",
        invalidPassword: "Invalid password",
      },
      panel: {
        editGroup: "Edit %name%",
        editDescription: "Edit the information of the selected group"
      },
      resetPassword: {
        hi: "Hello, ",
        insertNewPassword: "Enter new password",
        newPassword: "New password",

        currentPassword: "Current password",
        insertCurrentPassword: "Enter the current password",

        insertConfirmPassword: "Enter the confirmation of new password",
        confirmPassword: "Confirm password",

        oldPassword: "Old password",
        insertOldPassword: "Enter the old password",

        passwordNotMatch: "Passwords do not match",
        createdPassword: "Password created, now log in normally", //Senha criada, agora faça o login normalmente
        requestError: "Request error",
        changePassword: "Change password",
        changingPassword: "Changing"
      },
      sidebar: {
        home: "Home",
        drive: "Files",
        groups: "Groups",
        users: "Users",
        patients: "Patients",
        guides: "Content",
        experts: "Experts",
        settings: "Settings",
        exit: "Log out",
      },
      home: {
        drive: "Files",
        users: "Users",
        patients: "Patients",
        guides: "Content",
        experts: "Experts",
      },
      drive: {
        title: "Files",
        description: "Manage the files",
        sendError: "Error sending file, please try again",
        deleteAlert: "Are you sure you want to delete the file '%name%'?",
        renameFile: "Rename file",
        delete: "Delete",
        rename: "Rename",
        import: "Import",
        sending: "sending"
      },
      groups: {
        title: "Groups",
        description: "Manage groups",
        new: "New Group",
        listUser: "Users",
        listState: "State",
        listInit: "Started in"
      },
      groupsDialog: {
        description: "Click 'Save' to keep the changes",
        editGroup: "Edit Group",
        newGroup: "New Group",
        errorAlert: "An error occurred while sending the information to the server",
        insertName: "Insert name",
        minCharacters: "Enter at least 3 characters",
        name: "Name",
        state: "State",
        category: "Category",
        address: "Address",
        email: "E-mail",
        phone: "Telephone",
        notes: "Notes",
        photo: "Photo",
        change: "Change",
        saving: "Saving",
        save: "Save"
      },
      users: {
        description: "Manage group users",
        new: "New user",
        listAccesses: "Accesses",
        listAccessesNumber: "access(es)",
        listEmail: "E-mail",
        listPhone: "Telephone",
        listRegistration: "Registered in",
        deleteDialog: "Are you sure you want to remove the user '%name%'?\nIMPORTANT, all information related to this user will be deleted, including consumption data!"
      },
      usersDialog: {
        description: "Click 'Save' to keep the changes",
        editUser: "Edit user",
        newUser: "New user",
        name: "Name",
        minCharacters: "Enter at least 3 characters.",
        insertName: "Insert name",
        phone: "Telephone",
        email: "E-mail",
        insertEmail: "Insert e-mail",
        crm: "ID Number",
        role: "Role",
        linkedin: "Profile",
        notes: "Notes",
        permissions: "Permissions",
        photo: "Photo",
        change: "Modify",
        newPassword: "Generate new password",
        passwordTempAlert: "Temporary password copied!",
        saving: "Saving",
        save: "Save"
      },
      userInteraction: {
        title: "Interactions",
        description: "View consumption data for %name%",
        noteUpdateError: "Failed to update notes",
        listSave: "Save",
        listStep: "Step",
        listDuration: "Duration",
        listResponse: "Response",
        listDiagnostic: "Diagnostic",
        listGuide: "Content",
        listPatient: "Patient",
        listStartedAt: "Started at" ,
        listUpload: "Upload",
        listNotes: "Notes",
        listMainInformation: "Main informations",
        listEndedAt: "Ended at",
        deleteAlert: "Are you sure you want to delete this interaction?",
      },
      patients: {
        description: "Manage the patient list",
        new: "New patient",
        listInteractions: "Interactions",
        listNoInteractions: "No interactions",
        listOneInteractions: "1 interaction",
        listNInteractions: "%n% interactions",
        listRegistration: "Registration",
        deleteAlert: "Are you sure you want to remove the patient '%name%'?\nIMPORTANT, all information related to this patient will be deleted, including consumption data!"
      },
      patientsDialog: {
        editPatient: "Edit patient",
        newPatient: "New patient",
        description: "Click 'Save' to keep the changes",
        name: "Name",
        minCharacters: "Enter at least 3 characters.",
        insertName: "Insert name",
        mothersName: "Mother's name",
        idNumber: "ID Number",
        daysLife: "Days of life",
        notes: "Notes",
        saving: "Saving",
        save: "Save"
      },
      patientInteraction: {
        title: "Interactions",
        description: "View exam results related to patient '%name%'",
        print: "Print",
        listGuide: "Exam",
        listUser: "User",
        listStartedAt: "Started at" ,
        listUpload: "Upload",
        deleteAlert: "Are you sure you want to delete this information?",
        printFailed: "An error occurred while printing the exam results, please try again"
      },
      guides: {
        description: "Manage the group content list",
        name: "Name",
        enabled: "Enabled"
      },
      experts: {
        description: "Manage the group's list of experts",
        new: "New contact",
        deleteAlert: "Are you sure you want to remove the specialist '%name%'?"
      },
      expertsDialog: {
        description: "Click 'Save' to keep the changes'",
        editExpert: "Edit contact",
        newExpert: "New contact",
        name: "Name",
        minCharacters: "Enter at least 3 characters.",
        insertName: "Insert name",
        insertObject: "Insert the Object ID",
        role: "Role",
        photo: "Photo",
        change: "Modify",
        saving: "Saving",
        save: "Save"
      },
      settings: {
        description: "Click 'Save' to keep the changes",
        name: "Name",
        minCharacters: "Enter at least 3 characters.",
        insertName: "Insert name",
        state: "State",
        category: "Category",
        address: "Address",
        email: "E-mail",
        link: "Virtual room link",
        operatorEmail: "Examiner e-mail",
        phone: "Telephone",
        notes: "Notes",
        delete: "Delete",
        deleteAlert: "Are you sure you want to delete the group '%name%'?\n\nIMPORTANT, all users with their information will be deleted!",
        photo: "Photo",
        change: "Modify",
        sendError: "An error occurred while sending the information to the server",
        saving: "Saving",
        save: "Save"
      }
    }
  }