import { Guide } from "./guide";
import { Patient } from "./patient";
import { RemoteExpert } from "./remoteExpert";
import { User } from "./user";

export enum GroupStatus {
    "running" = 1, 
    "finished" = 2, 
    "stopped" = 3
};
export const GroupStatusValues = Object.keys(GroupStatus).filter((item) => {
    return isNaN(Number(item));
});
export enum GroupCategory {
    "public" = 1, 
    "private" = 2, 
    "clinic" = 3, 
    "others" = 4
}
export const GroupCategoryValues = Object.keys(GroupCategory).filter((item) => {
    return isNaN(Number(item));
});

export type Group = {
    id: number;
    name: string;
    avatar: string | null;
    status: GroupStatus;
    category: GroupCategory;
    phone: string;
    email: string;
    address: string;
    teamsLink: string;
    teamsEmail: string;
    notes: string;
    users: User[];
    patients: Patient[];
    guides: Guide[];
    experts: RemoteExpert[];
    createdAt: Date;
}