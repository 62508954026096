import React from 'react';
import './globals.css'
import ReactDOM from 'react-dom/client';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import LogoutPage from 'app/login/logout';
import LoginPage from 'app/login';
import PainelLayout from 'app/painel/layout';
import Painel from 'app/painel/page';
import { GroupUserPage } from 'app/painel/[groupId]/GroupUserPage';
import { GroupPatientPage } from 'app/painel/[groupId]/GroupPatientPage';
import { GroupGuidePage } from 'app/painel/[groupId]/GroupGuidePage';
import { UserPage } from 'app/painel/[groupId]/[userId]/page';
import { PatientPage } from 'app/painel/[groupId]/[patientId]/page';
import { LayoutGroupPage } from 'app/painel/[groupId]/layout';
import { GroupExpertPage } from 'app/painel/[groupId]/GroupExpertPage';
import { GroupSettingPage } from 'app/painel/[groupId]/GroupSettingPage';
import { DrivePage } from 'app/painel/[groupId]/DrivePage';
import { HomePage } from 'app/painel/[groupId]/HomePage';
import ResetPasswordPage from 'app/login/resetPassword';
import AdminSettings from 'app/painel/AdminSettings';
import "./translate";

const router = createBrowserRouter([
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/resetPassword",
      element: <ResetPasswordPage />,
    },
    {
      path: "/logout",
      element: <LogoutPage />,
    },
    {
      path: "/",
      element: <Navigate to="/grupos" replace/>
    },
    {
      path: "/admin",
      element: <PainelLayout/>,
      children: [
        {
          path: "/admin",
          element: <AdminSettings/>
        }
      ]
    },
    {
      path: "/grupos",
      element: <PainelLayout/>,
      children: [
        {
          path: "/grupos",
          element: <Painel/>
        },
        {
          path: "/grupos/:groupId",
          element: <LayoutGroupPage/>,
          children: [
            {
              path: "/grupos/:groupId",
              element: <HomePage/>,
            },
            {
              path: "/grupos/:groupId/drive",
              element: <DrivePage/>,
            },
            {
              path: "/grupos/:groupId/usuarios",
              children: [
                {
                  path: "/grupos/:groupId/usuarios",
                  element: <GroupUserPage/>,
                },
                {
                  path: "/grupos/:groupId/usuarios/:userId",
                  element: <UserPage/>
                } 
              ]
            },
            {
              path: "/grupos/:groupId/pacientes",
              children: [
                {
                  path: "/grupos/:groupId/pacientes",
                  element: <GroupPatientPage/>,
                },
                {
                  path: "/grupos/:groupId/pacientes/:patientId",
                  element: <PatientPage/>
                } 
              ]
            },
            {
              path: "/grupos/:groupId/roteiros",
              element: <GroupGuidePage/>,
            },
            {
              path: "/grupos/:groupId/especialistas",
              element: <GroupExpertPage/>,
            },
            {
              path: "/grupos/:groupId/configuracoes",
              element: <GroupSettingPage/>,
            }
          ]
        } 
      ]
    }
]);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
